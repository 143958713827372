import { FieldDef, FieldValue } from './base'
import { TypeOf } from '@/types'
import { clone } from 'ramda'

export class ArrayFieldDef<T> extends FieldDef<Array<T>> {
  constructor(
    readonly label: string,
    readonly items: FieldDef<T>,
    readonly itemTitle: (item: T) => string,
    private readonly defaultItems: T[]
  ) {
    super()
  }
  
  default() {
    return clone(this.defaultItems)
  }
  
  static create = <ItemDef extends FieldDef<any>>(props: {
    label: string,
    items: ItemDef,
    itemTitle: (item: TypeOf<ItemDef>) => string
    defaultItems?: Array<TypeOf<ItemDef>>
  }) => new ArrayFieldDef(
    props.label,
    props.items,
    props.itemTitle,
    props.defaultItems ?? []
  )
}

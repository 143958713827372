import { FieldDef } from './base'
import { CssColor } from '@/types'

export class CssColorFieldDef extends FieldDef<CssColor> {
  constructor(
    readonly label: string,
    private defaultValue: CssColor
  ) {
    super()
  }
  
  default() {
    return this.defaultValue
  }
  
  static create = (props: {
    label: string,
    defaultValue?: CssColor
  }) => new CssColorFieldDef(props.label, props.defaultValue ?? [ 0, 0, 0, 0 ] as CssColor)
  
}

import { defineBlock, defineComponent } from '@blocks/Block'
import { allPagesExpect, CourseTariffLink } from '@/types'
import { field as f } from '../../fields'
import React from 'react'
import { CoursePromoCardQuery, useCoursePromoCardQuery } from '@/graphql/generated'
import { Alert, Typography } from 'antd'
import { useAuthContext } from '@/auth/AuthContext'
import styled from '@emotion/styled'
import { colors, gradientStyles, LayoutSize, respondTo } from '@/styles'
import { Link } from '@blocks'
import { AvailableCourseBadge } from '@blocks/smart/SchoolMap'
import { isSSR } from '@/const'

const Section = styled.section`
  max-width: 1120px;
  margin: 1rem auto;
  padding: 0 1rem;
`

const Bar = styled.div`
  display: flex;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  background: #FFF;
  border: 1px solid ${colors.gray3};
  border-radius: 8px;
  min-height: 200px;
  max-width: 1080px;
  overflow: hidden;
  margin-bottom: 1.5rem;
  position: relative;
  align-items: stretch;

  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.18);
    transform: scale(1.006, 1.006);
  }
`

const CourseStyled = styled(Bar)`
  cursor: pointer;

  & > div:first-of-type {
    padding: 1rem 1.5rem;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    flex: 0 0 35%;
    max-width: 340px;
    position: relative;

    & > .ant-typography {
      display: none;

      ${respondTo(LayoutSize.MOBILE, `
        display: block;
        color: ${colors.white};
        font-weight: 600;
        font-size: 1rem;
        position: relative;
        z-index: 1;
      `)}
    }

    &:after {
      background: linear-gradient(180deg, rgba(33, 33, 33, 0) 0%, #212121 100%);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100px;
      max-height: 100%;
      content: '';
    }

    ${respondTo(LayoutSize.MOBILE, `
      flex: 0 0 100%;
      max-width: 100%;
    `)}
  }

  & > div:nth-of-type(2) {
    width: 200px;
    flex: 1;
    padding: 1.5rem 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h1 {
      color: ${colors.gray9};
      font-size: 1.5rem;
      line-height: 2rem;
    }
`

export const LinkBtn = styled.a`
  flex: 0 0 32px;

  ${respondTo(LayoutSize.MOBILE, `
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0;
    opacity: 0;
    z-index: 1;
    .ant-btn {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  `)}
`

const def = defineBlock('Smart.CoursePromoCard' as const, {
  blockName: 'Промо курса',
  isApplicableTo: allPagesExpect('Signup', 'Login'),
  fields: {
    course: f.courseTariff({
      label: 'Курс'
    }),
    enrollBtnLabel: f.text({
      label: 'Текст кнопки',
      defaultValue: 'Подробнее'
    }),
  }
})

const CoursePromoCardRender: React.FC<{
  enrollLink: string,
  course: NonNullable<CoursePromoCardQuery['availableCourse']>,
  enrollBtnLabel: string
}> = ({
  enrollLink,
  course: {
    title,
    isFree,
    description,
    imageGradient,
    image
  },
  enrollBtnLabel
}) => {
  return <Section>
    <CourseStyled>
    <div style={gradientStyles(imageGradient, image)}>
      <AvailableCourseBadge>
        {isFree ? 'Бесплатный курс' : 'Платный курс'}
      </AvailableCourseBadge>
      <Typography.Paragraph ellipsis={{ rows: 2 }}>{title}</Typography.Paragraph>
    </div>
    <div>
      <div>
        <Typography.Title ellipsis>{title}</Typography.Title>
        <Typography.Paragraph type='secondary' ellipsis={{ rows: 2 }}>{description}</Typography.Paragraph>
      </div>
      <div>
        <Link href={'/enroll/' + enrollLink} passHref prefetch={false}>
          <LinkBtn className="ant-btn">{enrollBtnLabel}</LinkBtn>
        </Link>
      </div>
    </div>
  </CourseStyled>
  </Section>
}

const CourseCardLoader: React.FC<{ link: CourseTariffLink, enrollBtnLabel: string }> = ({ link, enrollBtnLabel }) => {
  const { user } = useAuthContext()
  const { data, loading } = useCoursePromoCardQuery({
    skip: isSSR,
    variables: { link },
    errorPolicy: 'ignore'
  })
  if (isSSR || (loading && !data)) {
    return null
  }
  if (!data) {
    if (user?.isTeacherPreview) {
      return <Alert
        message="Курс не найден"
        description="Убедитесь в правильности настроек блкоа"
        type="warning"
        showIcon
      />
    }
    return null
  }
  return <CoursePromoCardRender
    enrollLink={link}
    course={data.availableCourse}
    enrollBtnLabel={enrollBtnLabel}
  />
}

const CoursePromoCard = defineComponent(def, ({ values: { course, enrollBtnLabel } }) => {
  if (!course) {
    return null
  }
  return <CourseCardLoader link={course.enrollLink} enrollBtnLabel={enrollBtnLabel}/>
})

export default CoursePromoCard

import { FieldDef } from './base'
import { Button } from 'antd'
import React from 'react'
import { clone } from 'ramda'
import { LinkData } from '@/types'
import { pageUrlByLink } from '@/utils/pageUrl'
import { Link } from '@blocks'

export const ButtonSizes = [ 'small', 'middle', 'large' ] as const
export type ButtonSize = typeof ButtonSizes[number]

export const ButtonTypes = [ 'default', 'primary', 'ghost', 'dashed', 'link', 'text' ] as const
export type ButtonType = typeof ButtonTypes[number]

export interface ButtonFieldProps {
  title: string
  type: ButtonType
  size?: ButtonSize
  link: LinkData
}

export class ButtonFieldDef extends FieldDef<ButtonFieldProps> {
  constructor(
    readonly label: string,
    private defaultValue: ButtonFieldProps
  ) {
    super()
  }

  default() {
    return clone(this.defaultValue)
  }

  static create = (props: {
    label: string,
    defaultProps?: Partial<ButtonFieldProps>
  }) => new ButtonFieldDef(props.label, {
    title: 'Кнопка',
    type: 'primary',
    size: 'middle',
    link: {
      _type: 'Page',
      page: 'Landing'
    },
    ...props.defaultProps
  })
}

export const ButtonField: React.FC<{ fieldProps: ButtonFieldProps }> = ({
  fieldProps: {
    type,
    size,
    title,
    link
  }
}) => {
  if (!link) {
    return <></>
  }
  const href = pageUrlByLink(link)
  return <Link href={href} passHref>
    <Button size={size} type={type}>{title}</Button>
  </Link>
}

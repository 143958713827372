import { FieldDef } from './base'

type EditorType = 'Checkbox' | 'Switch'

export class BooleanFieldDef extends FieldDef<boolean> {
  constructor(
    readonly label: string,
    readonly editorType: EditorType,
    private defaultValue: boolean
  ) {
    super()
  }

  default() {
    return this.defaultValue
  }

  static create = (props: {
    label: string,
    editorType?: EditorType
    defaultValue?: boolean
  }) => new BooleanFieldDef(props.label, props.editorType ?? 'Checkbox', props.defaultValue ?? true)
}

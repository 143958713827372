import React from 'react'
import { defineBlock, defineComponent } from './Block'
import styled from '@emotion/styled'
import { colors, layout, LayoutSize, respondTo } from '@/styles'
import { useCurrentSchool } from '@/auth/AuthContext'
import { field as f } from '@blocks/fields'
import { Container } from '@/components/tailwind'
import xw from 'xwind'

const def = defineBlock('SchoolBasicHero' as const, {
  blockName: 'Шапка школы с изображением',
  fields: {
    heading: f.text({
      label: 'Заголовок',
      defaultValue: '',
      placeholder: 'Название школы'
    }),
    subtitle: f.text({
      label: 'Подзаголовок',
      defaultValue: '',
      placeholder: 'Подзаголовок школы'
    }),
  }
})

const HeaderContent = styled.div`
  padding-top: 150px;
  position: relative;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
`

const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  padding: 1.5rem 3rem;
  width: 100%;
  box-shadow: 0px 2px .5rem rgba(0, 0, 0, 0.15);
  background: ${colors.white};
  border-radius: .5rem;

  & > div {
    display: inline-flex;
    align-items: center;
  }

  ${respondTo(LayoutSize.MOBILE, `
    padding: .5rem 1rem;
    margin: 0;
    border-radius: 0;
    h1 {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  `)}
`
const HeaderImage = styled.img`
  flex: 0 0 5rem;
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
  margin-right: 2rem;
  object-fit: cover;
  ${respondTo(LayoutSize.MOBILE, `
    flex: 0 0 4rem;
    width: 4rem;
    height: 4rem;
    margin-right: 1.5rem;
  `)}
`
const HeaderBg = styled.div`
  height: 210px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`



const SchoolBasicHero = defineComponent(def, ({id, values}) => {
  const { coverImage, avatar, ...school } = useCurrentSchool()
  const heading = values.heading || school.name
  const subtitle = values.subtitle || school.subtitle
  return (
    <HeaderContent data-block={id}>
      {coverImage && <HeaderBg style={{ background: `url(${coverImage}) no-repeat`, backgroundSize: 'cover' }}/>}
      {!coverImage &&
      <HeaderBg style={{ background: `url('/svg/blocks/SchoolBasicHero.svg') no-repeat`, backgroundSize: 'cover' }}/>}
      <Container>
        <HeaderInfo>
          <div>
            {avatar && <HeaderImage src={avatar} alt=""/>}
            <div>
              <h1 css={xw`text-base sm:text-xl md:text-3xl font-medium text-black mb-0 sm:mb-1`}>{heading}</h1>
              {subtitle && <span css={xw`text-gray-500`}>{subtitle}</span>}
            </div>
          </div>
        </HeaderInfo>
      </Container>
    </HeaderContent>
  )
})

export default SchoolBasicHero

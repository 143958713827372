import { FieldDef, FieldsDef, FieldsValue } from './base'
import { head, keys, map } from 'ramda'

type Enum<Options extends Record<string, string>> = keyof Options

export class EnumFieldDef<Options extends Record<string, string>> extends FieldDef<Enum<Options>> {
  constructor(
    readonly label: string,
    readonly options: Options
  ) {
    super()
  }
  
  default(): Enum<Options>  {
    return keys(this.options)[0]
  }
  
  static create = <Options extends Record<string, string>>(props: {
    label: string,
    options: Options,
  }) => new EnumFieldDef<Options>(props.label, props.options)
}

import React from 'react'
import { Badge } from 'antd'
import { BellOutlined as IconBell } from '@ant-design/icons'
import { useNotificationsCountQQuery } from '@/graphql/generated'
import { NAV } from '@/nav'
import { useDocumentVisibility } from 'ahooks'
import { colors } from '@/styles'
import { useRouter } from 'next/router'
import { Link } from '@blocks'
import styled from '@emotion/styled'
import { isSSR, PollInterval } from '@/const'

interface NotificationsMenuProps {
  onTab?: () => void,
}

const NotificationsIcon = styled(IconBell)`
  font-size: 1.2rem;
  padding: 0 .25rem;

  &:hover {
    color: ${colors.blue} !important;
  }
`

export const NotificationsMenuItem: React.FC<NotificationsMenuProps> = ({ }) => {
  const { pathname } = useRouter()
  const notificationPageUrl = NAV.Notifications
  const isActive = pathname.includes(notificationPageUrl)
  const visibilityState = process.browser ? useDocumentVisibility() : 'visible'

  const { data } = useNotificationsCountQQuery({
    pollInterval: PollInterval,
    skip: isSSR || visibilityState !== 'visible',
    errorPolicy: 'ignore'
  })

  const count = data?.notifications.filter(n => !n.isRead).length ?? 0
  return <div style={{marginRight: '1rem'}}>
    <Badge count={count}>
      <Link href={NAV.Notifications}>
        <NotificationsIcon style={{color: isActive ? colors.blue : colors.gray8}}/>
      </Link>
    </Badge>
  </div>
}

export default NotificationsMenuItem

import { defineBlock, defineComponent } from '@blocks/Block'
import { field as f } from '@blocks/fields'
import { Container } from '@/components/tailwind'
import xw from 'xwind'
import React from 'react'
import MarkdownComponent from '@/components/ReactMarkdown'

const def = defineBlock('Markdown' as const, {
  blockName: 'Разметка Markdown',
  fields: {
    content: f.markdown ({
      label: 'Текст разметки Markdown',
      defaultValue: '## Markdown\n' +
        '\n' +
        '*Markdown* - <span style="color: green">распространенный язык разметки</span>, созданный с целью написания наиболее читаемого и удобного для правки текста.\n' +
        '\n' +
        'Есть поддержка списков.\n' +
        '- Один\n' +
        '- Два\n' +
        '\n' +
        'Цитат\n' +
        '> Блок с цитатой\n' +
        '>\n' +
        '> > Блок с цитатой внутри цитаты\n' +
        '\n' +
        'Конечно же [ссылок](https://ru.wikipedia.org/wiki/Markdown) и даже таблиц\n' +
        '\n' +
        '| Колонка 1     | Колонка 2     | Колонка 3  |\n' +
        '| ------------- |:-------------:| -----------:|\n' +
        '| Значение 1  | Значение 2  | 1000 Руб |\n' +
        '| Значение 3  | Значение 4  | 900 Руб |\n' +
        '| Значение 5  | Значение 6  | 500 Руб |\n' +
        '\n' +
        'Вот [тут вы найдете "шпаргалку"](https://github.com/glprt/Markdown-Cheatsheet/blob/master/README.md) по оформлению текста'
    }),
    width: f.enum({
      label: 'Ширина блока',
      options: {
        Full: 'По ширине страницы',
        Medium: 'Средняя',
        Small: 'Узкая'
      }
    })
  }
})

const Markdown = defineComponent(def, ({ id, values }) => {
  const { content, width } = values

  if (!content) {
    return <></>
  }

  return <Container blockId={id}>
    <div css={[xw`py-6 mx-auto`,
      width === 'Full' && xw`w-full`,
      width === 'Medium' && xw`w-10/12`,
      width === 'Small' && xw`w-8/12 md:w-6/12`
    ]}>
      <MarkdownComponent source={content} escapeHtml={true} />
    </div>
  </Container>
})

export default Markdown

import React from 'react'
import { defineBlock, defineComponent } from '../Block'
import styled from '@emotion/styled'
import { Typography } from 'antd'
import { useEnrollPageCtx } from '@/pages.impl/enroll/EnrollPageCtx'
import { colors, LayoutSize, respondTo } from '@/styles'
import { UnorderedListOutlined } from '@ant-design/icons'
import { AvailableCourseQuery } from '@/graphql/generated'
import { min2hours } from '@/utils/numeric'
import { field as f } from '../fields'
import { Container } from '@/components/tailwind'

const def = defineBlock('EnrollPage.CourseStructure' as const, {
  blockName: 'Содержание курса',
  isApplicableTo: [ 'Enroll' ],
  fields: {
    title: f.text({
      label: 'Заголовок',
      defaultValue: 'Что внутри курса'
    })
  }
})

const { Title } = Typography

const CourseSectionTitle = styled(Title)`
  color: ${colors.gray8};
  margin-top: 2rem;
`
const Lecture = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background: ${colors.white};
  padding: 1.5rem 2rem;
  margin: 1rem 0;
`
const LectureContent = styled.div`
  display: inline-flex;
  align-items: center;

  span {
    margin-right: 2rem;
    color: ${colors.blue};
  }

  h4 {
    margin: 0;
    color: ${colors.gray8} !important;
  }

  ${respondTo(LayoutSize.MOBILE, `
    h4 {
      font-size: 1rem;
    }
  `)}
`
const LectureDuration = styled.span`
  font-size: .875rem;
  color: ${colors.gray6};
  white-space: nowrap;
  margin-left: .5rem;

  strong {
    color: ${colors.gray8};
    font-weight: 600;
    width: 90px;
    text-align: center;
    display: inline-block;
  }

  ${respondTo(LayoutSize.MOBILE, `
    display: none;
  `)}
`

const CourseSection = styled.div`
  margin-top: 1.5rem;
`
type LectureData = AvailableCourseQuery['availableCourse']['sections'][number]['lectures'][number]

const AvailableCourseLecture: React.FC<LectureData & { index: number }> = ({ index, title, durationMinutes }) => (
  <Lecture>
    <LectureContent>
      <span>{index}</span>
      <Title level={4}>{title}</Title>
    </LectureContent>
    {!!durationMinutes && <LectureDuration>
      Продолжительность урока: <strong>{min2hours(durationMinutes)}</strong>
    </LectureDuration>}
  </Lecture>
)


const EnrollPageCourseStructure = defineComponent(def, ({ id, values }) => {
  const { sections } = useEnrollPageCtx().course
  const flatLectures = sections.map(s => s.lectures).flat()

  return <Container blockId={id}>
    <div style={{overflowY: 'scroll', padding: '10px'}}>
      <CourseSectionTitle level={3}>
        <UnorderedListOutlined style={{ color: colors.blue, marginRight: '1rem' }}/>
        {values.title}
      </CourseSectionTitle>
      {sections.map(section => (
        <CourseSection key={section.id}>
          <span>{section.title}</span>
          {section.lectures.map(l =>
            <AvailableCourseLecture key={l.id} index={flatLectures.indexOf(l) + 1} {...l}/>
          )}
        </CourseSection>
      ))}
    </div>
  </Container>
})

export default EnrollPageCourseStructure

import { defineBlock, defineComponent } from '@blocks/Block'
import { MyCoursesQuery, useMyCoursesQuery, useSchoolMapQuery } from '@/graphql/generated'
import { Collapse, Progress, Result, Skeleton } from 'antd'
import { ErrorResult } from '@/components/ErrorResult'
import React from 'react'
import { NAV } from '@/nav'
import { Link } from '@blocks'
import { colors, gradientStyles } from '@/styles'
import {
  StarOutlined as IconStar,
  ArrowRightOutlined as IconArrowRight,
  ClockCircleOutlined as IconClock,
  CheckOutlined as IconCheck
} from '@ant-design/icons'
import {
  CourseItem,
  CourseItemInfo,
  CourseItemImage,
  CourseItemBadge,
  ProgressInfo,
  ProgressHint,
  CollapsePanel,
  MyCoursesSection
} from './MyCourses.styles'
import { CourseDescription } from '@/components/CourseDescription'
import { BlockDescription } from '@blocks/base'

type Course = MyCoursesQuery['courses'][number]

const def = defineBlock('Smart.MyCourses' as const, {
  blockName: 'Все курсы студента',
  isApplicableTo: [ 'MyCourses' ],
  fields: {},
  description: <BlockDescription>
    Этот блок не имеет специальных настроек и отображает список курсов, на которые записался студент
  </BlockDescription>
})

enum CourseStatus {
  ACTIVE = 0,
  AVAILABLE = 1,
  PENDING = 2,
  COMPLETED = 3
}

interface CoursesTreeNode {
  id: CourseStatus
  title: string
  icon: string
  courses: Course[]
}

const renderIcon = (icon: string) => {
  const style = {
    color: colors.blue,
    paddingRight: '.75rem',
    fontSize: '1.25rem'
  }
  switch (icon) {
    case 'star':
      return <IconStar style={style}/>
    case 'arrow-right':
      return <IconArrowRight style={style}/>
    case 'clock':
      return <IconClock style={style}/>
    case 'check':
      return <IconCheck style={style}/>
    default:
      return null
  }
}

const buildCoursesTree = (courses: Course[]): CoursesTreeNode[] => {
  const tree: CoursesTreeNode[] = [
    { id: CourseStatus.ACTIVE, title: 'Текущие курсы', icon: 'star', courses: [] },
    { id: CourseStatus.AVAILABLE, title: 'Начните обучение', icon: 'arrow-right', courses: [] },
    { id: CourseStatus.PENDING, title: 'Обучение скоро начнется', icon: 'clock', courses: [] },
    { id: CourseStatus.COMPLETED, title: 'Курсы завершены', icon: 'check', courses: [] }
  ]
  courses.forEach(course => {
    const { lecturesCompleted, lecturesTotal } = course.progress
    let index = CourseStatus.AVAILABLE
    if (!course.isStarted) {
      index = CourseStatus.PENDING
    } else if (lecturesCompleted === lecturesTotal) {
      index = CourseStatus.COMPLETED
    } else if (lecturesCompleted > 0) {
      index = CourseStatus.ACTIVE
    }
    tree[index].courses.push(course)
  })
  tree.forEach(t => t.courses.sort((a, b) => a.orderNum - b.orderNum))
  return tree
}

const CourseDom: React.FC<Course & { index: CourseStatus }> = ({
  id,
  title,
  description,
  startingDate,
  isStarted,
  progress: { lecturesCompleted, lecturesTotal },
  imageGradient,
  image,
  index
}) => {
  const withProgress = isStarted && lecturesCompleted > 0 && lecturesCompleted !== lecturesTotal
  const isCompleted = lecturesCompleted === lecturesTotal
  let label: string | null = null
  if (withProgress) {
    label = 'Продолжить обучение'
  } else if (isStarted && lecturesCompleted === 0) {
    label = 'Приступить к обучению'
  } else if (isCompleted) {
    label = 'Перейти к курсу'
  }
  const courseLink = `/app/course/${id}`
  return (
    <CourseItem>
      <CourseItemImage style={gradientStyles(imageGradient, image)}>
        {index === CourseStatus.PENDING && <CourseItemBadge>
          {startingDate ? startingDate : 'Идет набор'}
        </CourseItemBadge>}
        <span>{title}</span>
        {withProgress && <ProgressInfo>
          <Progress percent={Math.round(lecturesCompleted * 100 / lecturesTotal)} showInfo={false}/>
          <ProgressHint><strong>{lecturesCompleted}</strong> / {lecturesTotal} уроков</ProgressHint>
        </ProgressInfo>}
      </CourseItemImage>
      <CourseItemInfo>
        <h3>{title}</h3>
        <CourseDescription
          title={title}
          description={description ?? null}
          label={label}
          path={courseLink}
        />
        <Link href={courseLink} passHref>
          <a className={`ant-btn ${withProgress ? 'ant-btn-primary' : ''}`}>
            {label}
          </a>
        </Link>
      </CourseItemInfo>
    </CourseItem>
  )
}

const MyCoursesList: React.FC<{ courses: Course[] }> = ({ courses }) => {
  return <Collapse
    expandIconPosition="right"
    bordered={false}
    style={{ background: 'transparent', paddingBottom: '1.5rem' }}
    defaultActiveKey={[
      CourseStatus.ACTIVE,
      CourseStatus.AVAILABLE,
      CourseStatus.PENDING,
    ]}
  >
    {buildCoursesTree(courses)
      .filter(n => n.courses.length > 0)
      .map((node, index) => (
        <CollapsePanel
          header={<>{renderIcon(node.icon)} {node.title} ({node.courses.length})</>}
          key={index}
        >
          {node.courses.map(c =>
            <CourseDom {...c} key={c.id} index={index}/>
          )}
        </CollapsePanel>
      ))}
  </Collapse>
}

const MyCourses = defineComponent(def, ({ id }) => {
  const { data, error, loading } = useMyCoursesQuery()

  const courses = data?.courses

  if (!courses && loading) {
    return <MyCoursesSection>
      <Skeleton active/>
      <br/>
      <Skeleton active/>
    </MyCoursesSection>
  }

  if (error) {
    return <MyCoursesSection>
      <ErrorResult error={error}/>
    </MyCoursesSection>
  }

  if (!courses || courses.length === 0) {
    return <MyCoursesSection>
      <Result
        status="info"
        title="Нет курсов"
        subTitle="Вы еще не записались ни на один курс"
        extra={
          <Link href={NAV.Home} passHref>
            <a className="ant-btn ant-btn-primary">
              Посмотреть доступные курсы
            </a>
          </Link>
        }
      />
    </MyCoursesSection>
  }

  return <MyCoursesSection data-block={id}>
    <MyCoursesList courses={courses}/>
  </MyCoursesSection>
})

export default MyCourses

import { Editor } from 'slate'

export const withCustomElements = <T extends Editor>(editor: T): T => {
  const {isInline, isVoid } = editor

  editor.isInline = (el) => {
    const {type} = el
    switch (type) {
      case 'info':
      case 'warning':
      case 'numbered-list':
      case 'bullet-list':
      case 'text-center':
      case 'text-left':
      case 'text-right':
      case 'heading-one':
      case 'heading-two':
      case 'heading-three':
      case 'heading-four':
      case 'heading-six':
      case 'list-item':
      case 'alert-message':
        return false
      case 'student':
      case 'link':
        return true
      default:
        return isInline(el)
    }
  }

  editor.isVoid = (element) => {
    return element.type === 'student' ? true : isVoid(element)
  }

  return editor
}

import React, { ReactElement } from 'react'
import { map } from 'ramda'
import { FieldsDef, FieldsValue } from './fields'
import { Flatten, PageBlockId, PageType, PageTypes } from '@/types'

type BlockTypeLiteral = string

type NewBlock<Type extends BlockTypeLiteral, Fields extends FieldsDef> = {
  readonly _type: Type,
  readonly values: FieldsValue<Fields>
}

export interface BlockDefinition<Type extends BlockTypeLiteral, Fields extends FieldsDef> {
  readonly _type: Type
  readonly fields: Fields
  readonly blockName: string
  isApplicableTo: PageType[]
  create(): Flatten<NewBlock<Type, Fields>>
  description?: React.ReactNode
}

export const defineBlock = <Type extends BlockTypeLiteral, Fields extends FieldsDef>(
  _type: Type,
  props: {
    isApplicableTo?: PageType[],
    blockName: string,
    fields: Fields,
    description?: React.ReactNode
  }
): BlockDefinition<Type, Fields> => ({
  _type,
  isApplicableTo: [...PageTypes],
  ...props,
  create: () => ({
    _type,
    values: map(f => f.default(), props.fields)
  })
})

type Props<Fields extends FieldsDef> = Flatten<{
  readonly id: PageBlockId
  readonly values: FieldsValue<Fields>
}>

export interface Block<Type extends BlockTypeLiteral, Fields extends FieldsDef> extends BlockDefinition<Type, Fields> {
  (props: Props<Fields>): ReactElement | null;
}

export const defineComponent = <T extends BlockTypeLiteral, F extends FieldsDef>(
  def: BlockDefinition<T, F>,
  render: React.FC<Props<F>>
): Block<T, F> => {
  return Object.assign(render, def)
}

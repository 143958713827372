import React from 'react'
import type { PickByTypename } from '@/types'
import { defineBlock, defineComponent } from '../Block'
import { Breadcrumb, Button, Empty, Progress, Skeleton, Typography } from 'antd'
import { SchoolMapQuery, useSchoolMapQuery } from '@/graphql/generated'
import { ErrorResult } from '@/components/ErrorResult'
import { lecturePlural } from '@/utils/plural'
import styled from '@emotion/styled'
import { colors, gradientStyles, LayoutSize, respondTo } from '@/styles'
import { byOrderNum } from '@/utils/sortByOrderNum'
import { useRouter } from 'next/router'
import { useQueryParams } from '@/utils/useQueryParams'
import { Link, useIsPreview } from '@blocks'
import { NAV } from '@/nav'
import { Container } from '@/components/tailwind'
import { BlockDescription } from '../base'
import {CourseDescription} from '@/components/CourseDescription'

const def = defineBlock('Smart.SchoolMap' as const, {
  blockName: 'Все курсы школы',
  isApplicableTo: [ 'Home', 'Landing' ],
  fields: {},
  description: <BlockDescription>
    Этот блок не имеет специальных настроек и отображает список опубликованых курсов школы
  </BlockDescription>
})

type AnyItem = SchoolMapQuery['schoolMap'][number]

type SchoolMapItemFolder = PickByTypename<AnyItem, 'SchoolMapItemFolder'>
type SchoolMapItemAnyCourse = PickByTypename<AnyItem, 'SchoolMapItemCourse'>['course']
type CourseType = PickByTypename<SchoolMapItemAnyCourse, 'Course'>
type AvailableCourseType = PickByTypename<SchoolMapItemAnyCourse, 'AvailableCourse'>

const Section = styled.section`
  max-width: 1120px;
  margin: 1rem auto;
  padding: 0 1rem;
`

const Bar = styled.div`
  display: flex;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  background: #FFF;
  border: 1px solid ${colors.gray3};
  border-radius: 8px;
  min-height: 200px;
  overflow: hidden;
  margin-bottom: 1.5rem;
  position: relative;
  align-items: stretch;
  max-width: 1080px;

  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.18);
    transform: scale(1.006, 1.006);
  }
`

const FolderStyled = styled(Bar)`
  & > div:first-of-type {
    padding: 1rem 1.5rem;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    flex: 0 0 35%;
    max-width: 340px;
    position: relative;

    & > span {
      display: none;

      ${respondTo(LayoutSize.MOBILE, `
        display: block;
        color: ${colors.white};
        font-weight: 600;
        font-size: 1rem;
        position: relative;
        z-index: 1;
      `)}
    }

    &:after {
      background: linear-gradient(180deg, rgba(33, 33, 33, 0) 0%, #212121 100%);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100px;
      max-height: 100%;
      content: '';
    }

    ${respondTo(LayoutSize.MOBILE, `
      flex: 0 0 100%;
      max-width: 100%;
    `)}
  }

  & > div:nth-of-type(2) {
    width: 200px;
    flex: 1;
    padding: 1.5rem 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h1 {
      color: ${colors.gray9};
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  .ant-btn {
    ${respondTo(LayoutSize.MOBILE, `
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0;
    opacity: 0;
    z-index: 1;
    .ant-btn {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  `)}
  }
`
const Folder: React.FC<SchoolMapItemFolder & { onClick: () => void }> = ({
  title,
  description,
  image,
  imageGradient,
  onClick
}) => {
  return <FolderStyled>
    <div style={gradientStyles(imageGradient, image)}>
      <span>{title}</span>
    </div>
    <div>
      <div>
        <Typography.Title ellipsis>{title}</Typography.Title>
        <Typography.Paragraph type='secondary' ellipsis={{ rows: 2 }}>{description}</Typography.Paragraph>
      </div>
      <div>
        <Button onClick={onClick}>
          Посмотреть курсы
        </Button>
      </div>
    </div>
  </FolderStyled>
}

const CourseStyled = styled(Bar)`
  & > div:first-of-type {
    padding: 1rem 1.5rem;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    flex: 0 0 35%;
    max-width: 340px;
    position: relative;

    & > .ant-typography {
      display: none;

      ${respondTo(LayoutSize.MOBILE, `
        display: block;
        color: ${colors.white};
        font-weight: 600;
        font-size: 1rem;
        position: relative;
        z-index: 1;
      `)}
    }

    &:after {
      background: linear-gradient(180deg, rgba(33, 33, 33, 0) 0%, #212121 100%);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100px;
      max-height: 100%;
      content: '';
    }

    ${respondTo(LayoutSize.MOBILE, `
      flex: 0 0 100%;
      max-width: 100%;
    `)}
  }

  & > div:nth-of-type(2) {
    width: 200px;
    flex: 1;
    padding: 1.5rem 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h1 {
      color: ${colors.gray9};
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
`

export const LinkBtn = styled.a`
  flex: 0 0 32px;

  ${respondTo(LayoutSize.MOBILE, `
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0;
    opacity: 0;
    z-index: 1;
    .ant-btn {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  `)}
`

export const CourseProgressInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: .5rem;
  position: relative;
  z-index: 1;

  .ant-progress-inner {
    background: rgba(255, 255, 255, 0.1);
  }

  & > span {
    white-space: nowrap;
    min-width: 100px;
    margin-left: .5rem;
    color: rgba(255, 255, 255, 0.7);
    text-align: right;

    strong {
      color: ${colors.white};
    }
  }
`

const Course: React.FC<CourseType> = ({
  id,
  title,
  description,
  isStarted,
  progress: { lecturesCompleted, lecturesTotal },
  imageGradient,
  image,
}) => {
  const inProgress = isStarted && lecturesCompleted > 0 && lecturesCompleted !== lecturesTotal
  let isBtnPrimary = false
  let label
  if (inProgress) {
    label = 'Продолжить обучение'
    isBtnPrimary = true
  } else if (isStarted && lecturesCompleted === 0) {
    label = 'Приступить к обучению'
    isBtnPrimary = true
  } else {
    label = 'Перейти к курсу'
  }

  return <CourseStyled>
    <div style={gradientStyles(imageGradient, image)}>
      <Typography.Paragraph type='secondary' ellipsis={{ rows: 2 }}>{title}</Typography.Paragraph>
      {isStarted && <CourseProgressInfo>
        <Progress percent={Math.round(lecturesCompleted * 100 / lecturesTotal)} showInfo={false}/>
        <span><strong>{lecturesCompleted}</strong> / {lecturePlural(lecturesTotal)}</span>
      </CourseProgressInfo>}
    </div>
    <div>
      <div>
        <Typography.Title ellipsis>{title}</Typography.Title>
        <CourseDescription
          title={title}
          description={description ?? null}
          label={label}
          path={`/app/course/${id}`}
        />
      </div>
      <div>
        <Link
          href={{
            pathname: NAV.Course.Index,
            query: { id }
          }}
          prefetch={false}>
          <LinkBtn className={`ant-btn ${isBtnPrimary ? 'ant-btn-primary' : ''}`}>
            {label}
          </LinkBtn>
        </Link>
      </div>
    </div>
  </CourseStyled>
}

export const AvailableCourseBadge = styled.div`
  background: rgba(51, 51, 51, 0.4);
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 1.5rem;
  line-height: 1rem;
  padding: 0 .75rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1;
  color: ${colors.white};
`

const AvailableCourse: React.FC<AvailableCourseType> = ({
  title,
  isFree,
  description,
  imageGradient,
  image,
  enrollLink
}) => {
  return <CourseStyled>
    <div style={gradientStyles(imageGradient, image)}>
      <AvailableCourseBadge>
        {isFree ? 'Бесплатный курс' : 'Платный курс'}
      </AvailableCourseBadge>
      <Typography.Paragraph ellipsis={{ rows: 2 }}>{title}</Typography.Paragraph>
    </div>
    <div>
      <div>
        <Typography.Title ellipsis>{title}</Typography.Title>
        <CourseDescription
          title={title}
          description={description ?? null}
          label={'Подробнее'}
          path={`/enroll/${enrollLink}`}
        />
      </div>
      <div>
        <Link href={'/enroll/' + enrollLink} passHref prefetch={false}>
          <LinkBtn className="ant-btn">
            Подробнее
          </LinkBtn>
        </Link>
      </div>
    </div>
  </CourseStyled>
}

const AnyCourse: React.FC<SchoolMapItemAnyCourse> = (course) => {
  switch (course.__typename) {
    case 'AvailableCourse':
      return <AvailableCourse {...course}/>
    case 'Course':
      return <Course {...course}/>
  }
}

const FolderDescription = styled(Typography.Paragraph)`
  font-size: 1rem;
  white-space: pre-wrap;
  margin-top: 1.5rem;
  color: ${colors.black};
`

const SchoolMapDom: React.FC<{ id: string, schoolMap: SchoolMapQuery['schoolMap'] }> = ({ id, schoolMap }) => {
  const isPreview = useIsPreview()
  const router = useRouter()
  const params = useQueryParams<{ parentId?: string }>()
  const parentId = params.parentId ? parseFloat(params.parentId) : null
  const items = schoolMap.filter(i => i.parentId === parentId).sort(byOrderNum)

  const setParentId = (newParent: number | null) => {
    if (isPreview) return
    router.push({
      pathname: router.pathname,
      query: {
        ...router.query,
        parentId: newParent
      }
    })
  }

  const parent = parentId ? schoolMap.find(i => i.id === parentId) as SchoolMapItemFolder : null

  return <Container blockId={id}>
    {parent && <div style={{ marginBottom: '2rem' }}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <span style={{ cursor: 'pointer' }} onClick={() => setParentId(null)}>Все курсы школы</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{parent.title}</Breadcrumb.Item>
      </Breadcrumb>
      {parent.description && (
        <FolderDescription>
          {parent.description}
        </FolderDescription>
      )}
    </div>}
    {items.length === 0 && (
      <Empty description="Нет доступных курсов"/>
    )}
    {items.map(item => <Section key={item.id}>
      {item.__typename === 'SchoolMapItemFolder' && <Folder {...item} onClick={() => setParentId(item.id)}/>}
      {item.__typename === 'SchoolMapItemCourse' && <AnyCourse {...item.course}/>}
    </Section>)}
  </Container>
}


const SchoolMap = defineComponent(def, ({id}) => {
  const { data, error, loading } = useSchoolMapQuery()

  const schoolMap = data?.schoolMap

  if (!schoolMap && loading) {
    return <Container blockId={id}>
      <Skeleton active/>
      <br/>
      <Skeleton active/>
    </Container>
  }

  if (error) {
    return <Container blockId={id}>
      <ErrorResult error={error}/>
    </Container>
  }

  return <div style={{overflow: 'scroll'}}>
    <SchoolMapDom id={id} schoolMap={schoolMap ?? []} />
  </div>
})

export default SchoolMap


import { FieldDef } from './base'

export class TextFieldDef extends FieldDef<string> {
  constructor(
    readonly label: string,
    readonly multiline: boolean,
    private defaultValue: string,
    readonly placeholder: string | null
  ) {
    super()
  }
  
  default() {
    return this.defaultValue
  }
  
  static create = (props: {
    label: string,
    multiline?: boolean,
    defaultValue?: string
    placeholder?: string
  }) => new TextFieldDef(props.label, props.multiline ?? false, props.defaultValue ?? '', props.placeholder ?? null)
}

import React from 'react'
import { defineBlock, defineComponent } from '../../Block'
import { Container } from '@/components/tailwind'
import { allPagesExpect } from '@/types'
import { useCurrentSchool } from '@/auth/AuthContext'
import { field as f } from '@blocks/fields'
import {
  SchoolContactType,
} from '@/graphql/generated'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faCommentAlt } from '@fortawesome/free-solid-svg-icons'
import {
  faTelegramPlane,
  faOdnoklassnikiSquare,
  faTwitter,
  faViber,
  faVk,
  faWhatsapp,
  faYoutube,
  faFacebookF,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import {
  ContactsBlock,
  ContactsItem, ContactsItemLink,
  ContactsItemSubTitle,
  ContactsItemTitle,
  ContactsRow,
  ContactsTitle,
  IconWrapper,
} from '@blocks/smart/SchoolContacts/SchoolContacts.styles'
import { RightOutlined } from '@ant-design/icons'
import { colors } from '@/styles'
import { BlockDescription } from '@blocks/base'

const def = defineBlock('Smart.SchoolContacts' as const, {
  blockName: 'Контакты школы',
  isApplicableTo: allPagesExpect('Signup', 'Login'),
  fields: {
    title: f.text({
      label: 'Заголовок блока',
      defaultValue: 'Остались вопросы?'
    }),
  },
  description: <BlockDescription>
    Контакты можно добавить на странице настроек школы.
    Блок отображается, если указан хотя бы один контакт.
  </BlockDescription>
})

const ICONS_MAP = {
  [SchoolContactType.Web]: { icon: faCommentAlt, color: '#FBA23C' },
  [SchoolContactType.Telegram]: { icon: faTelegramPlane, color: '#61A8DE' },
  [SchoolContactType.Facebook]: { icon: faFacebookF, color: '#4C77A6', },
  [SchoolContactType.Instagram]: { icon: faInstagram, color: '#95AFC0' },
  [SchoolContactType.Odnoklassniki]: { icon: faOdnoklassnikiSquare, color: '#ed812b' },
  [SchoolContactType.Twitter]: { icon: faTwitter, color: '#00acee' },
  [SchoolContactType.Viber]: { icon: faViber, color: '#665CAC' },
  [SchoolContactType.Vk]: { icon: faVk, color: '#4c75a3' },
  [SchoolContactType.Whatsapp]: { icon: faWhatsapp, color: '#4ac959' },
  [SchoolContactType.Youtube]: { icon: faYoutube, color: '#c4302b' },
  [SchoolContactType.Phone]: { icon: faPhone, color: '#95AFC0' },
}

interface ContactsData {
  contactType: SchoolContactType,
  link: string
}

const generateLink = ({ contactType, link }: ContactsData) => {
  switch (contactType) {
    case SchoolContactType.Facebook:
      return link.includes('fb.me') ? link : `https://fb.me/${link}`
    case SchoolContactType.Vk:
      return link.includes('vk.com') ? link : `https://vk.com/${link}`
    case SchoolContactType.Viber:
      return link.includes('viber://') ? link : `viber://add?number=${link.replace(/[ +-]/g, '')}`
    case SchoolContactType.Phone:
      return link.includes('tel:') ? link : `tel:${link.replace(/[ -]/g, '')}`
    case SchoolContactType.Whatsapp:
      return link.includes('whatsapp://') ? link : `whatsapp://send?phone=${link.replace(/[ +-]/g, '')}`
    case SchoolContactType.Telegram:
      return link.includes('t.me') ? link : `https://t.me/${link.replace(/@/g, '')}`
    case SchoolContactType.Youtube:
      return link.includes('youtu.be') || link.includes('youtube') ? link : `https://youtu.be/${link}`
    case SchoolContactType.Instagram:
      return link.includes('instagram.com') ? link : `https://instagram.com/${link}`
    case SchoolContactType.Twitter:
      return link.includes('twitter.com') ? link : `https://twitter.com/${link}`
    case SchoolContactType.Odnoklassniki:
      return link.includes('ok.ru') ? link : `https://ok.ru/${link}`
    default:
      return link
  }
}

const SchoolContacts = defineComponent(def, ({ id, values: { title } }) => {
  const { contacts } = useCurrentSchool()

  if (contacts.length === 0) {
    return <></>
  }

  return <Container blockId={id}>
    <ContactsBlock>
      <ContactsTitle level={3}>
        {title}
      </ContactsTitle>
      <ContactsRow>
        {contacts.map((c, i) => (
          <ContactsItem xs={24 / contacts.length} key={c.contactType + i}>
            <IconWrapper style={{ backgroundColor: ICONS_MAP[c.contactType].color || colors.deepBlue }}>
              <FontAwesomeIcon icon={ICONS_MAP[c.contactType].icon}/>
            </IconWrapper>
            <ContactsItemTitle level={4}>{c.title}</ContactsItemTitle>
            {!!c.subtitle && <ContactsItemSubTitle>{c.subtitle}</ContactsItemSubTitle>}
            <ContactsItemLink href={generateLink(c)} target="_blank" rel="noopener noreferrer">
              {c.link} <RightOutlined/>
            </ContactsItemLink>
          </ContactsItem>
        ))}
      </ContactsRow>
    </ContactsBlock>
  </Container>
})

export default SchoolContacts

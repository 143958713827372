import { FieldDef } from './base'
import { CssDimension } from '@/types'

export class CssDimensionFieldDef extends FieldDef<CssDimension> {
  constructor(
    readonly label: string,
    private defaultValue: CssDimension
  ) {
    super()
  }
  
  default() {
    return this.defaultValue
  }
  
  static create = (props: {
    label: string,
    defaultValue: CssDimension
  }) => new CssDimensionFieldDef(props.label, props.defaultValue)
  
}

import { FieldDef } from './base'
import { CourseId, CourseTariffId, CourseTariffLink } from '@/types'

export interface CourseTariffFieldData {
  courseId: CourseId
  tariffId: CourseTariffId
  enrollLink: CourseTariffLink
}

export class CourseTariffFieldDef extends FieldDef<CourseTariffFieldData | null> {

  constructor(
    readonly label: string
  ) {
    super()
  }

  default() {
    return null
  }

  static create = (props: {
    label: string,
  }) => new CourseTariffFieldDef(props.label)
}

import React, { HTMLAttributes, useCallback, useMemo } from 'react'
import { Element as ElementCmp, Leaf } from './renderers'
import { createEditor } from 'slate'
import { Editable, Slate, withReact } from 'slate-react'
import styled from '@emotion/styled'
import { withCustomElements } from './withCustomElements'
import { SlateDoc } from '@/components/slate/types'

interface Props extends HTMLAttributes<HTMLDivElement> {
  value: SlateDoc
}

export const SlateEditable = styled(Editable)`
  h1, h2, h3, h4 {
    font-weight: 500;
    margin: 1rem 0;
  }

  p {
    margin-bottom: 0.5rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  h3 {
    font-size: 1.15rem;
  }

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`


const SlateViewer: React.FC<Props> = ({ value, className, ...rest }) => {
  const cs = 'ant-typography ' + (className ?? '')
  const renderElement = useCallback(props => <ElementCmp {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(() => withCustomElements(withReact(createEditor())), [])

  return <Slate
    editor={editor}
    value={value}
    onChange={() => null}
  >
    <div className={cs} {...rest}>
      <SlateEditable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        readOnly
      />
    </div>
  </Slate>
}


export default React.memo(SlateViewer)

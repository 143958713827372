import styled from '@emotion/styled'
import {colors, LayoutSize, respondTo} from '@/styles'
import { Col, Row, Typography } from 'antd'

export const ContactsBlock = styled.div`
  padding: 2rem 0;
`
export const ContactsTitle = styled(Typography.Title)`
  max-width: 960px;
  margin: 0 auto 1.5rem;
  color: ${colors.gray8} !important;

  ${respondTo(LayoutSize.MOBILE, `
    padding: 0 1rem;
  `)}
`

export const ContactsRow = styled(Row)`
  display: flex;
  width: 100%;
  max-width: 960px;
  overflow: auto;
  margin: 0 auto;

  ${respondTo(LayoutSize.MOBILE, `
    overflow: auto;
    flex-wrap: nowrap;
  `)}
`

export const ContactsItem = styled(Col)`
  padding: 1.5rem;
  word-break: break-word;

  ${respondTo(LayoutSize.MOBILE, `
    border: 1px solid ${colors.gray5};
    border-radius: .5rem;
    min-width: 216px;
    margin: 0 .75rem;
    max-width: 400px;
  `)}
`

export const IconWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: .5rem;

  svg {
    height: 1.25rem;
    color: ${colors.white};
  }
`

export const ContactsItemTitle = styled(Typography.Title)`
  color: ${colors.gray8} !important;
  margin-bottom: 0.5rem;
`

export const ContactsItemSubTitle = styled.p`
  color: ${colors.gray7};
  line-height: 1.5rem;
  padding: 0;
  margin-bottom: 0.5rem;
`

export const ContactsItemLink = styled(Typography.Link)`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  font-size: 1.2rem;

  .anticon {
    font-size: .8rem;
    margin-left: .7rem;
    vertical-align: baseline;
  }
`

export function redirectWithForm({ method, url, params }: {
  url: string,
  method?: 'GET' | 'POST',
  params?:  Record<string, string>
}) {

  if (!url) {
    return
  }

  const form = document.createElement('form')
  form.setAttribute('method', method ?? 'POST')
  form.setAttribute('action', url)

  if (params) {
    Object.keys(params).forEach(key => {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = key
      input.value = params[key]
      form.appendChild(input)
    })
  }

  document.body.appendChild(form)
  form.submit()
}

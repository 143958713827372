import styled from '@emotion/styled'
import React, { memo } from 'react'

export const BlockSection = styled.section`
  max-width: 1120px;
  margin: 0 auto;
`

const Description = styled.div`
  margin: 1rem 0;
  background: #fafafa;
  color: rgba(0, 0, 0, 0.65);
  padding: 12px 16px;
  border: 1px solid #d9d9d9b3;
  border-radius: 2px;
  font-size: 13px;
  line-height: 16px;
`

export const BlockDescription: React.FC = memo(({ children }) =>
  <Description>
    {children}
  </Description>
)

import React from 'react'
import { defineBlock, defineComponent } from '../Block'
import { Typography } from 'antd'
import { field as f } from '../fields'
import { ButtonField } from '../fields/ButtonFieldDef'
import Typed from 'react-typed'
import { Player } from '@lottiefiles/react-lottie-player'
import { Container } from '@/components/tailwind'
import xw from 'xwind'

const { Text } = Typography

const def = defineBlock('Hero.1' as const, {
  blockName: 'Hero1',
  isApplicableTo: ['Landing'],
  fields: {
    title: f.text({
      label: `Заголовок`,
      defaultValue: 'Раскройте'
    }),
    typedvariants: f.text({
      label: `Варианты продолжения`,
      multiline: true,
      defaultValue: 'свой потенциал\nсвое будущее'
    }),
    description: f.text({
      label: `Описание`,
      multiline: true,
      defaultValue: 'C нашими курсами вы получите самые необходимые знания и навыки, чтобы получить работу своей мечты.'
    }),
    buttons: f.array({
      label: 'Кнопки и ссылки',
      items: f.button({
        label: '',
        defaultProps: {
          size: 'large'
        }
      }),
      itemTitle: item => item.title,
      defaultItems: [
        {
          title: 'Регистрация',
          type: 'primary',
          size: 'large',
          link: {
            _type: 'Page',
            page: 'Signup'
          }
        },
        {
          title: 'Узнать подробнее',
          type: 'link',
          size: 'large',
          link: {
            _type: 'Web',
            href: '/'
          }
        }
      ]
    })
  }
})

/*

<div class="container mx-auto px-4">
  <div class="flex items-center sm:items-start flex-col sm:flex-row space-x-8 justify-items-stretch">
    <div class="flex-shrink-0 w-8/12 sm:w-2/5 lg:w-2/6 mb-8 sm:mb-0">
      <div>
        <img src="https://htmlstream.com/preview/front-v3.2/assets/svg/illustrations/reading.svg"/>
      </div>
    </div>
    <div class="flex-grow sm:pt-4">
      <h1 class="text-4xl md:text-5xl font-medium text-black mb-4 sm:mb-6">
        Раскройте <span class="text-blue-500">свой потенциал</span>
      </h1>
      <p class="text-mg md:text-xl text-gray-400 mb-6">
        C нашими курсами вы получите самые необходимые знания и навыки, чтобы получить работу своей мечты.
      </p>
      <div class="flex space-x-4 justify-center md:justify-start">
        <button class="py-2 px-3 text-white text-sm font-medium transition bg-blue-500 hover:bg-blue-600 rounded shadow cursor-pointer">Войти</button>
        <button class="py-2 px-3 text-white text-sm font-medium transition bg-blue-500 hover:bg-blue-600 rounded shadow cursor-pointer">Войти</button>
      </div>
    </div>
  </div>
</div>

*/


const Hero1 = defineComponent(def, ({ id, values }) => {
  const { title, typedvariants, description, buttons } = values
  return <Container blockId={id}>
    <div css={xw`flex items-center flex-col sm:flex-row space-x-8 justify-items-stretch`}>
      <div css={xw`flex-shrink-0 w-8/12 sm:w-2/5 lg:w-5/12 mb-8 sm:mb-0`}>
        <Player
          autoplay
          loop
          src="/lootie/laptop-working.json"
          style={{
            maxWidth: '100%',
            height: 'auto'
          }}
        />
      </div>
      <div css={xw`flex-grow`}>
        <h1 css={xw`text-4xl md:text-5xl font-medium text-black mb-4 sm:mb-6`}>
          {title}
          {title && typedvariants ? ' ' : null}
          {typedvariants && <span css={xw`text-blue-500`}>
              <Typed
                strings={typedvariants.split('\n')}
                typeSpeed={40}
                smartBackspace={false}
                loop
                backSpeed={25}
                backDelay={3000}/>
            </span>}
        </h1>
        <p css={xw`text-base md:text-xl text-gray-400 mb-4 sm:mb-6`}>
          {description}
        </p>
        <div css={xw`flex space-x-3 justify-center md:justify-start`}>
          {buttons.map((b, i) => <ButtonField key={i} fieldProps={b}/>)}
        </div>
      </div>
    </div>
  </Container>
})

export default Hero1

import NextLink from 'next/link';
import { cloneElement } from 'react'

let isPreview: boolean | null = null

export function useIsPreview() {
  if (isPreview !== null) {
    return isPreview
  }

  // For SSG and SSR always return false
  if (typeof window === 'undefined') return false;

  return false;
}

export function setIsPreview(preview: boolean) {
  isPreview = preview
  Link = isPreview ? PreviewLink : NextLink
}

const PreviewLink: typeof NextLink = (props) => {
  const link = NextLink(props)
  return cloneElement(link, {
    onMouseEnter: undefined,
    onClick: (e) => {
      e.stopPropagation();
      e.preventDefault()
      return false
    }
  })
}

export let Link: typeof NextLink = isPreview ? PreviewLink : NextLink

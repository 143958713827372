import { FieldDef } from './base'

export type ImageFieldData =
  ({ src: string } & (
    {
      width?: never
      height?: never
    } |
    {
      width: number
      height: number
    }
    )) | null

export class ImageFieldDef extends FieldDef<ImageFieldData> {
  constructor(
    readonly label: string,
    private defaultValue: ImageFieldData,
  ) {
    super()
  }

  default() {
    return this.defaultValue
  }

  static create = (props: {
    label: string,
    defaultValue?: ImageFieldData
  }) => new ImageFieldDef(props.label, props.defaultValue ?? null)
}

import { FieldDef, FieldsDef, FieldsValue } from './base'
import { map } from 'ramda'

// interface CreateFn<Fields extends FieldsDef> {
//   (label: string, fields: Fields): NestedFieldDef<Fields>
//   (fields: Fields): NestedFieldDef<Fields>
// }
type CreateArgs<Fields extends FieldsDef> = [string, Fields] | [Fields]

export class NestedFieldDef<Fields extends FieldsDef> extends FieldDef<FieldsValue<Fields>> {
  constructor(
    readonly label: string | null,
    readonly fields: Fields
  ) {
    super()
  }

  default(): FieldsValue<Fields> {
    return map(f => f.default(), this.fields)
  }

  static create<Fields extends FieldsDef>(...args: CreateArgs<Fields>) {
    if (args.length === 2) {
      return new NestedFieldDef(args[0], args[1])
    } else {
      return new NestedFieldDef(null, args[0])
    }
  }
}

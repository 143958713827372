/** @jsxImportSource @emotion/react */
import React, { forwardRef } from 'react'
import { RenderElementProps as ElementProps, RenderLeafProps as LeafProps } from 'slate-react'
import styled from '@emotion/styled'
import { useAuthContext } from '@/auth/AuthContext'

const getAlertProps = (type: string) => ({
  className: `ant-alert ant-alert-${type} ant-alert-no-icon`,
  style: {
    marginTop: '1rem',
    marginBottom: '1rem'
  }
})

const AlertContent = styled.div`

  min-height: 1rem;

  & > h1, h2 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  & > p {
    white-space: pre-wrap;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

`

const StudentTag = forwardRef<HTMLSpanElement>(({ children, ...rest }, ref) => {
  const { user } = useAuthContext()
  return <span {...rest} ref={ref}>
    {user?.name ?? 'Незнакомец'}
    {children}
  </span>
})

export const Element: React.FC<ElementProps> = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>
    case 'heading-four':
      return <h4 {...attributes}>{children}</h4>
    case 'heading-five':
      return <h5 {...attributes}>{children}</h5>
    case 'heading-six':
      return <h6 {...attributes}>{children}</h6>
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bullet-list':
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'code':
      return <code {...attributes}>{children}</code>
    case 'paragraph':
      return <p {...attributes}>{children}</p>
    case 'text-left':
      return <div style={{ textAlign: 'left' }} {...attributes}>{children}</div>
    case 'text-center':
      return <div style={{ textAlign: 'center' }} {...attributes}>{children}</div>
    case 'text-right':
      return <div style={{ textAlign: 'right' }} {...attributes}>{children}</div>
    case 'alert-message':
      return <p {...attributes}>{children}</p>
    case 'student':
      return <StudentTag {...attributes}>{children}</StudentTag>
    case 'warning':
    case 'info':
      return <div {...getAlertProps(element.type)} {...attributes}>
        <AlertContent className="ant-alert-content">
          {children}
        </AlertContent>
      </div>
    case 'link':
      return <a {...attributes} href={element.url as string} target="_blank" rel="noopener noreferrer">{children}</a>
    default:
      return <p {...attributes}>{children}</p>
  }
}

export const Leaf: React.FC<LeafProps> = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }
  if (leaf.code) {
    children = <code>{children}</code>
  }
  if (leaf.italic) {
    children = <em>{children}</em>
  }
  if (leaf.underline) {
    children = <u>{children}</u>
  }
  if (leaf.strikethrough) {
    children = <s>{children}</s>
  }
  return <span {...attributes}>{children}</span>
}

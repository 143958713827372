import React from 'react'
import { defineBlock, defineComponent } from './Block'
import styled from '@emotion/styled'
import { Space, Typography } from 'antd'
import { field as f } from './fields'
import { ButtonField } from '@blocks/fields/ButtonFieldDef'
import { CssColor } from '@/types'
import { cssColor } from '@/utils/cssColor'
import { Container } from '@/components/tailwind'
import xw from 'xwind'

const { Paragraph, Title } = Typography

const def = defineBlock('DiagonalHero' as const, {
  blockName: 'Предложение (диагональное)',
  fields: {
    label: f.text({
      label: 'Надпись сверху',
      defaultValue: 'Предложение'
    }),
    title: f.text({
      label: `Заголовок`,
      defaultValue: 'Монетизируйте ваш опыт и знания'
    }),
    description: f.text({
      label: `Подзаголовок`,
      multiline: true,
      defaultValue: 'Вы обладаете уникальными навыками и ценными знаниями? Делитесь ими с другими! Создать собственный кабинет для обучения очень просто! Упакуйте ваши знания и заработайте на этом.'
    }),
    bgColor: f.css.color({
      label: `Цвет фона`,
      defaultValue: [ 242, 242, 242 ] as CssColor
    }),
    buttons: f.array({
      label: 'Кнопки и ссылки',
      items: f.button({
        label: ''
      }),
      itemTitle: item => item.title,
      defaultItems: [
        {
          title: 'Получить',
          type: 'primary',
          link: {
            _type: 'Page',
            page: 'Landing'
          }
        },
        {
          title: 'Узнать подробнее',
          type: 'link',
          link: {
            _type: 'Page',
            page: 'Landing'
          }
        }
      ]
    })
  }
})


const Section = styled.section`
  --diagonal-section-offset: 50px;

  position: relative;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  padding-top: calc(var(--diagonal-section-offset) / 2);
  padding-bottom: calc(var(--diagonal-section-offset) / 2);
  clip-path: polygon(0% 0%, 100% var(--diagonal-section-offset), 100% 100%, 0% calc(100% - var(--diagonal-section-offset)));
`

const DiagonalHero = defineComponent(def, ({ id, values }) => {
  const { label, title, description, bgColor, buttons } = values

  return <Section style={{ backgroundColor: cssColor(bgColor) }} data-block={id}>
    <div css={xw`container px-4 py-20 lg:py-16`}>
      <Paragraph style={{ marginBottom: '0.5rem' }} type='secondary'>
        {label}
      </Paragraph>
      <div>
        <Title>{title}</Title>
        <Paragraph>{description}</Paragraph>
      </div>
      <Space>
        {buttons.map((b, i) => <ButtonField key={i} fieldProps={b}/>)}
      </Space>
    </div>
  </Section>
})

export default DiagonalHero

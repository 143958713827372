/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import React from 'react'
import { Avatar } from 'antd'
import type { AvatarProps } from 'antd/lib/avatar'
import BoringAvatar from 'boring-avatars'

type Props = Omit<AvatarProps, 'src'> & {
  user: {
    name: string
    avatar?: string | null
  }
}

const avatarColors = [
  '#A0D911', // lime-6
  '#FFC53D', // gold-5
  '#FF4D4F', // red-5
  '#FA8C16', // orange-6
  '#85A5FF', // geekblue-4
]

export const UserAvatar: React.FC<Props> = ({ user, ...rest }) => {
  const props = user.avatar ? {
    ...rest,
    src: user.avatar,
    alt: user.name,
  } : {
    ...rest,
    alt: user.name,
    src: <div css={css`
      > svg {
        width: 100%;
        height: 100%;
      }
    `}>
      <BoringAvatar
        name={user.name}
        variant="beam"
        colors={avatarColors}
      />
    </div>
  }

  return <Avatar {...props} />
}

import React from 'react'
import { defineBlock, defineComponent } from '../Block'
import Image from 'next/image'
import { field as f } from '../fields'
import { Container } from '@/components/tailwind'
import xw from 'xwind'

const item = (icon: string, title: string, description: string) => ({
  title,
  description,
  image: {
    src: `/svg/icons/${icon}`
  }
})

const def = defineBlock('Features.1' as const, {
  blockName: 'Список преимуществ',
  fields: {
    caption: f.text({
      label: 'Надпись сверху',
      defaultValue: 'что внутри'
    }),
    title: f.text({
      label: `Заголовок`,
      defaultValue: 'Что вы получите с нашими курсами'
    }),
    features: f.array({
      label: `Список преимуществ`,
      items: f.nested({
        image: f.image({
          label: `Изображение`,
        }),
        title: f.text({
          label: `Преимущество`,
          defaultValue: 'Обучение Онлайн'
        }),
        description: f.text({
          label: `Краткое описание`,
          defaultValue: 'Видеолекции, вебинары, тесты и домашние задания. Постоянный доступ к материалам курса'
        })
      }),
      itemTitle: item => item.title,
      defaultItems: [
        item('icon-18.svg', 'Обучение Онлайн', 'Видеолекции, вебинары, тесты и домашние задания. Постоянный доступ к материалам курса.'),
        item('icon-24.svg', 'Актуальность', 'Только самые последние и актуальные материалы.'),
        item('icon-4.svg', 'Интерактив', 'В наших курсах много интересных и практических заданий, и конечно с обратной связи.'),
        item('icon-7.svg', 'Помощь', 'В обучении всегда полезна помощь опытных товарищей.'),
      ]
    })
  }
})

/*

<div class="container mx-auto p-4">
  <div class="text-center mb-8 md:mb-10">
    <div class="text-sm font-medium mb-1 text-gray-500 text-opacity-90">что внутри</div>
    <h2 class="text-xl md:text-2xl font-semibold text-black text-opacity-90">Что вы получите с нашими курсами</h2>
  </div>
  <div class="grid grid-cols-1 md:grid-flow-row md:grid-cols-2 2xl:grid-cols-3 gap-8 md:gap-10 place-items-start">
    <div class="flex items-center md:items-start space-x-6">
      <div class="flex-shrink-0 w-12 md:w-14 md:pt-3">
        <img src="http://localhost:3000/svg/icons/icon-19.svg" />
      </div>
      <div class="flex-auto">
        <h4 class="text-base md:text-lg font-medium text-black text-opacity-90 mb-1">1 Обучение Онлайн</h4>
        <p class="text-sm md:text-base text-gray-500 text-opacity-80">Видеолекции, вебинары, тесты и домашние задания. Постоянный доступ к материалам курса.</p>
      </div>
    </div>

    <div class="flex items-center md:items-start space-x-6">
      <div class="flex-shrink-0 w-12 md:w-14 md:pt-3">
        <img src="http://localhost:3000/svg/icons/icon-4.svg" />
      </div>
      <div class="flex-auto">
        <h4 class="text-base md:text-lg font-medium text-black text-opacity-90 mb-1">2 Обучение Онлайн</h4>
        <p class="text-sm md:text-base text-gray-500 text-opacity-80">Видеолекции, вебинары, тесты и домашние задания. Постоянный доступ к материалам курса.</p>
      </div>
    </div>

    <div class="flex items-center md:items-start space-x-6">
      <div class="flex-shrink-0 w-12 md:w-14 md:pt-3">
        <img src="http://localhost:3000/svg/icons/icon-7.svg" />
      </div>
      <div class="flex-auto">
        <h4 class="text-base md:text-lg font-medium text-black text-opacity-90 mb-1">3 Обучение Онлайн</h4>
        <p class="text-sm md:text-base text-gray-500 text-opacity-80">Видеолекции, вебинары, тесты и домашние задания. Постоянный доступ к материалам курса.</p>
      </div>
    </div>
  </div>
</div>

*/

const Features1 = defineComponent(def, ({ id, values }) => {
  const { caption, title, features } = values
  return <Container blockId={id}>
    <div css={xw`p-4 my-6 max-w-5xl mx-auto`}>
      <div css={xw`text-center mb-8 md:mb-10`}>
        {caption && <div css={xw`text-sm font-medium mb-1 text-gray-500 text-opacity-90`}>{caption}</div>}
        <h2 css={xw`text-xl md:text-2xl font-semibold text-black text-opacity-90`}>
          {title}
        </h2>
      </div>
      <div css={xw`grid grid-cols-1 md:grid-flow-row md:grid-cols-2 gap-8 md:gap-10 place-items-start`}>
        {features.map(({ image, title, description }, i) =>
          <div css={xw`flex items-center md:items-start space-x-6`} key={i}>
            {image && (
              <div css={xw`flex-shrink-0 w-12 md:w-14 md:pt-3`}>
                <img src={image.src} alt={title}/>
              </div>
            )}
            <div css={xw`flex-auto`}>
              <h4 css={xw`text-base md:text-lg font-medium text-black text-opacity-90 mb-1`}>
                {title}
              </h4>
              <p css={xw`text-sm md:text-base text-gray-500 text-opacity-80`}>
                {description}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  </Container>
  // return <div css={xw`overflow-hidden my-3`}>
  //   <div css={xw`container space-2 space-top-lg-3 position-relative`}>
  //     <div css={xw`w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9`}>
  //       {caption && <span css={xw`d-block small fw-bold text-capitalize mb-2`}>{caption}</span>}
  //       <h2>{title}</h2>
  //     </div>
  //     <div css={xw`row justify-content-lg-center`}>
  //       {features.map(({ image, title, description }, i) =>
  //         <div css={xw`col-md-6 col-lg-5 mb-3 mb-md-5 mb-lg-7`} key={i}>
  //           <div css={xw`d-flex pe-lg-5`}>
  //             {image && <div css={xw`w-100 me-4`} style={{ maxWidth: '3.5rem' }}>
  //               {image.width && (
  //                 <Image
  //                   src={image.src}
  //                   layout="responsive"
  //                   width={image.width}
  //                   height={image.height}
  //                 />
  //               )}
  //               {!image.width && (
  //                 <img css={xw`img-fluid" src={image.src} alt="`}/>
  //               )}
  //             </div>}
  //             <div css={xw`flex-fill`}>
  //               <h4>{title}</h4>
  //               <p>{description}</p>
  //             </div>
  //           </div>
  //         </div>
  //       )}
  //     </div>
  //   </div>
  // </div>
})

export default Features1

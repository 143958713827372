import ReactMarkdown from 'react-markdown/with-html'
import styled from '@emotion/styled'
import { colors } from '@/styles'
import React from 'react'
import xw from 'xwind'
import gfm from 'remark-gfm'

const Markdown = styled.div`

  h1, h2, h3, h4 {
    font-weight: 500;
    margin: 1rem 0;
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  h3 {
    font-size: 1.15rem;
  }

  blockquote {
    border-left: 4px solid ${colors.gray5};
    padding: 0 1rem;
    color: ${colors.gray7};
  }

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`

interface MarkdownComponentProps {
  source: string
  escapeHtml: boolean
}

const MarkdownComponent: React.FC<MarkdownComponentProps> = ({ source, escapeHtml }) => (
  <div css={xw`prose prose-sm sm:prose`}>
    <Markdown>
      <ReactMarkdown
        source={source}
        escapeHtml={escapeHtml}
        plugins={[ gfm ]}
      />
    </Markdown>
  </div>
)

export default MarkdownComponent

import React from 'react'
import { defineBlock, defineComponent } from '../Block'
import styled from '@emotion/styled'
import {Avatar} from 'antd'
import { LayoutSize, respondTo } from '@/styles'
import { BlockDescription } from '../base'
import { Container } from '@/components/tailwind'
import SlateViewer from '@/components/slate/SlateViewer'
import {allPagesExpect} from '@/types'
import {useCurrentSchool} from '@/auth/AuthContext'

const def = defineBlock('Smart.TeacherBiography' as const, {
  blockName: 'О преподавателе',
  isApplicableTo: allPagesExpect('Signup', 'Login'),
  fields: {},
  description: <BlockDescription>
    Этот блок управляется настройками школы.
    Блок отображается, только если данные о преподавателе указаны.
  </BlockDescription>
})

export const TeacherBiographyPreview = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  padding: 1.5rem .5rem;
  .ant-avatar {
    margin-right: 3rem;
    flex: 0 0 8rem;
  }
  & > .ant-typography {
    flex: 0 1 100%;
  }
  ${respondTo(LayoutSize.MOBILE, `
    flex-direction: column;
    text-align: center;
    margin-bottom: 0;
    .ant-avatar {
      margin: 0 0 1rem;
    }
    .ant-typography {
      margin: 0;
      font-size: 1rem;
    }
  `)}
`

const TeacherBiography = defineComponent(def, ({ id }) => {
  const { teacherBiography } = useCurrentSchool()

  if (!teacherBiography) {
    return <></>
  }

  return <Container blockId={id}>
    <TeacherBiographyPreview>
      {teacherBiography.avatarUrl && <Avatar size={128} src={teacherBiography.avatarUrl}/>}
      <SlateViewer value={teacherBiography.about}/>
    </TeacherBiographyPreview>
  </Container>
})

export default TeacherBiography

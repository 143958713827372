import React, { useEffect, useState } from 'react'
import { defineBlock, defineComponent } from '../Block'
import styled from '@emotion/styled'
import { Button, Col, message, Modal, Row, Typography } from 'antd'
import { useEnrollPageCtx } from '@/pages.impl/enroll/EnrollPageCtx'
import { colors, gradientStyles, LayoutSize, respondTo } from '@/styles'
import { useAuthContext, useCurrentSchool } from '@/auth/AuthContext'
import { ArrowRightOutlined, BookOutlined, ClockCircleOutlined, StarOutlined } from '@ant-design/icons'
import { BlockDescription } from '../base'
import { Container } from '@/components/tailwind'
import moment from 'moment'
import { field as f } from '@blocks/fields'
import { plural } from '@/utils/plural'
import FormattedPrice from '@/components/FormattedPrice'
import { Form, Input, SubmitButton } from 'formik-antd'
import { MailOutlined } from '@ant-design/icons/lib/icons'
import { Formik } from 'formik'
import yup from '@/yup.ru'
import { redirectWithForm } from '@/utils/redirect'
import { CourseTariffLink, SchoolId } from '@/types'
import { AsyncButton } from '@/components/AsyncButton'
import * as Sentry from '@sentry/nextjs'

const def = defineBlock('EnrollPage.Hero' as const, {
  blockName: 'Обложка курса',
  isApplicableTo: [ 'Enroll' ],
  fields: {
    statisticIsShown: f.boolean({
      label: 'Показать дополнительную информацию',
      editorType: 'Switch',
      defaultValue: true
    })
  },
  description: <BlockDescription>
    Этот блок управляется настройками курса
  </BlockDescription>
})

const { Title } = Typography

export const CourseHeader = styled.header`
  margin-top: 1rem;
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  position: relative;
  font-weight: 600;
`
export const CourseHeaderContent = styled.div`
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  position: absolute;
  width: 100%;
  height: 100%;
  color: ${colors.white};
  padding: 3.5rem;

  .ant-btn {
    margin-top: 1.5rem;
  }

  & > .ant-typography {
    color: ${colors.white} !important;
    white-space: pre-wrap;
  }

  & > footer {
    .ant-btn + .ant-btn {
      margin-left: 1rem;
    }
  }

  ${respondTo(LayoutSize.MOBILE, `
    padding: 1.5rem;
    border-radius: 0;

    .ant-typography-ellipsis {
      display: none;
    }

    h1 {
      font-size: 1.3rem;
      -webkit-line-clamp: 2;
      max-height: 58px;
    }

    .ant-btn {
      margin-top: 0.5rem;
    }
  `)}
`
export const CourseHeaderHint = styled.span`
  position: absolute;
  left: 3.5rem;
  bottom: 3.5rem;

  .anticon {
    margin-right: .5rem;
  }

  ${respondTo(LayoutSize.MOBILE, `
    position: static;
    display: block;
  `)}
`
export const CourseInfoCol = styled.div`
  color: ${colors.gray7};
  font-size: .75rem;
  line-height: 1.25rem;
  padding: 1.5rem;
  max-width: 255px;
  margin: 0 auto;

  label {
    font-size: 1rem;
    font-weight: 600;
    color: ${colors.gray6};
    border-bottom: 1px solid ${colors.gray};
    display: block;
    padding-bottom: .5rem;
    margin-bottom: 1rem;
  }

  > span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    color: ${colors.gray8};
    font-size: 1.5rem;
    white-space: nowrap;

    .anticon {
      color: ${colors.blue};
      margin-right: 1rem;
    }
  }
  ${respondTo(LayoutSize.MOBILE, `
    label {
      padding: 0;
      border: none;
    }
  `)}
`

const validateSchema = yup.object({
  email: yup.string().email().required(),
}).required()

type EmailFormData = yup.InferType<typeof validateSchema>

const goToPayment = async (input: {email: string, link: CourseTariffLink, schoolId: SchoolId}) => {
  Sentry.addBreadcrumb({ message: 'Payment input', data: input })
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/payments/init`, {
      method: 'POST',
      body: JSON.stringify(input),
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const text = await response.text()
    if (response.ok) {
      redirectWithForm(JSON.parse(text))
    } else {
      message.error('Ошибка при загрузке данных')
    }
  } catch (err) {
    Sentry.captureException(err)
    message.error('Ошибка при загрузке данных')
  }
}

const EnrollPageHero = defineComponent(def, ({ id, values: { statisticIsShown } }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const { id: schoolId } = useCurrentSchool()

  const { link: enrollLink, course: {
    title,
    description,
    startingDate,
    isAvailableImmediately,
    isFree,
    image,
    imageGradient,
    enrollPostUrl,
    price,
    currency,
    sections,
  }} = useEnrollPageCtx()
  const flatLectures = sections.map(s => s.lectures).flat()
  const lecturesCount = flatLectures.length

  const label = isFree ? 'Записаться на курс' : 'Приобрести курс'
  const user = useAuthContext().user
  const isTeacherPreview = user?.isTeacherPreview ?? false

  const email = user?.email

  const onPaymentClick = async () => {
    if (isFree) {
      window.location.href = enrollPostUrl
      return
    }
    if (email) {
      await goToPayment({email, link: enrollLink, schoolId })
      return
    }
    setIsModalVisible(true)
  }

  const onSubmit = async (data: EmailFormData) => {
    await goToPayment({ email: data.email, link: enrollLink, schoolId })
    return
  }

  return <>
      <Container blockId={id}>
      <CourseHeader>
        <CourseHeaderContent style={gradientStyles(imageGradient, image)}>
          <Title level={1} ellipsis={{rows: 3}} style={{ color: colors.white }}>{title}</Title>
          {description && <Typography.Paragraph ellipsis={{rows: 3, expandable: true, symbol: 'Подробнее'}}>
            {description}
          </Typography.Paragraph>}
          <CourseHeaderHint>
            {startingDate ? <ClockCircleOutlined/> : <ArrowRightOutlined/>}
            {startingDate && `Начнётся с ${startingDate}`}
            {!startingDate && isAvailableImmediately && 'Приступайте к обучению'}
            {!startingDate && !isAvailableImmediately && 'Записывайтесь на курс'}
          </CourseHeaderHint>
          <footer>
            {isTeacherPreview && (
              <Button
                type="primary"
                onClick={e => {
                  e.stopPropagation()
                  message.info('Функция недоступна во время предпросмотра')
                }}>
                {label}
              </Button>
            )}
            {!isTeacherPreview && (
              <AsyncButton type="primary" onClick={onPaymentClick}>
                {label}
              </AsyncButton>
            )}
          </footer>
        </CourseHeaderContent>
      </CourseHeader>
      {statisticIsShown &&
        <Row style={{marginTop: '1rem'}} wrap={true}>
          <Col md={7}>
            <CourseInfoCol data-testid="block-price">
              <label>Стоимость</label>
              <span>
                <StarOutlined/>
                {isFree ? 'Бесплатно' : <FormattedPrice price={price} currency={currency ?? 'RUB'}/>}
              </span>
            </CourseInfoCol>
          </Col>
          <Col md={10}>
            <CourseInfoCol data-testid="block-start">
              <label>Начало обучения</label>
              {startingDate ?
                <span><ClockCircleOutlined/> {moment(startingDate).format('DD MMMM YYYY')}</span> :
                <span><ClockCircleOutlined/> {isAvailableImmediately ? 'Доступно сразу' : 'Идёт набор'}</span>}
            </CourseInfoCol>
          </Col>
          <Col md={7}>
            <CourseInfoCol>
              <label>Структура курса</label>
              <span>
              <BookOutlined/>
                {lecturesCount} {plural(lecturesCount, 'урок', 'урока', 'уроков')}
              </span>
            </CourseInfoCol>
          </Col>
        </Row>}
    </Container>
    <Modal title={label} visible={isModalVisible} footer={null} onCancel={() => setIsModalVisible(false)}>
      <Typography.Text>Укажите почту на которую отправить данные для доступа к курсу</Typography.Text>
      <Formik<EmailFormData>
        initialValues={{email: ''}}
        onSubmit={onSubmit}
        validationSchema={validateSchema}>
        <Form>
          <Form.Item name="email">
            <Input
              addonBefore={<MailOutlined style={{ fontSize: '.875rem', color: colors.gray6 }}/>}
              name="email"
              size="large"
              placeholder="Введите Вашу почту"
            />
          </Form.Item>
          <SubmitButton block size="large">{isFree ? 'Получить доступ' : 'К оплате'}</SubmitButton>
        </Form>
      </Formik>
    </Modal>
  </>
})

export default EnrollPageHero

import { FieldDef } from './base'
import React from 'react'
import { clone } from 'ramda'
import { LinkData } from '@/types'

export class LinkFieldDef extends FieldDef<LinkData> {
  constructor(
    readonly label: string,
    private defaultValue: LinkData
  ) {
    super()
  }

  default() {
    return clone(this.defaultValue)
  }

  static create = (props: {
    label: string,
    default?: LinkData
  }) => new LinkFieldDef(props.label, props.default ?? { _type: 'Page', page: 'Landing' })
}

import { useUnmountedRef } from 'ahooks'
import { Button } from 'antd'
import type { ButtonProps } from 'antd/lib/button'
import * as React from 'react'
import { MouseEvent, useState } from 'react'

interface Props extends ButtonProps {
  onClick: (event: MouseEvent<HTMLElement>) => Promise<unknown>
}

export const AsyncButton: React.FC<Props> = ({onClick, children, ...rest}) => {
  const unmountRef: { current: boolean } = useUnmountedRef()
  const [isLoading, setLoading] = useState(false)
  const handleClick = async (event: MouseEvent<HTMLElement>) => {
    setLoading(true)
    try {
      await onClick(event)
    } finally {
      !unmountRef.current && setLoading(false)
    }
  }
  return <Button {...rest} loading={isLoading} onClick={handleClick}>{children}</Button>
}

import { FieldDef } from './base'
import React from 'react'

export class MarkdownFieldDef extends FieldDef<string> {
  constructor(
    readonly label: string,
    private defaultValue: string
  ) {
    super()
  }

  default() {
    return this.defaultValue
  }

  static create = (props: {
    label: string,
    defaultValue?: string
  }) => new MarkdownFieldDef(props.label, props.defaultValue ?? '')
}

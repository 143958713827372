import React from 'react'
import { Block } from './Block'
import Header from './Header'
import ImageBlock from './ImageBlock'
import Markdown from './Markdown'
import DiagonalHero from './DiagonalHero'
import { PageBlockId } from '@/types'
import { fromPairs } from 'ramda'
import { FieldsValue } from './fields'
import EnrollPageHero from './enrollPage/EnrollPageHero'
import EnrollPageCourseStructure from './enrollPage/EnrollPageCourseStructure'
import SchoolMap from './smart/SchoolMap'
import MyCourses from './smart/MyCourses'
import CoursePromoCard from './smart/coursePromo/CoursePromoCard'
import Features1 from './features/Features1'
import SchoolBasicHero from './SchoolBasicHero'
import Hero1 from './hero/Hero1'
import Content1 from '@blocks/Content.1'
import TeacherBiography from '@blocks/smart/TeacherBiography'
import SchoolContacts from './smart/SchoolContacts/SchoolContacts'

export const AllBlocks = [
  Header,
  SchoolMap,
  MyCourses,
  ImageBlock,
  Features1,
  DiagonalHero,
  Hero1,
  SchoolBasicHero,
  EnrollPageHero,
  EnrollPageCourseStructure,
  TeacherBiography,
  CoursePromoCard,
  Markdown,
  Content1,
  SchoolContacts
]

export type {
  Block
}
export {
  ImageBlock,
  DiagonalHero,
  Features1,
  Header,
  SchoolBasicHero,
  EnrollPageHero,
  EnrollPageCourseStructure,
  TeacherBiography,
  SchoolMap,
  MyCourses,
  Hero1,
  CoursePromoCard,
  Content1,
  Markdown,
  SchoolContacts
}
export * from './useIsPreview'

type AllBlocks = (typeof AllBlocks)[number]
export type BlockType = AllBlocks['_type']

type SelectBlockWithType<T extends BlockType, B extends Block<any, any>> = B extends Block<T, infer F> ?  Block<T, F> : never

export type BlockOf<T extends BlockType> = T extends BlockType ? SelectBlockWithType<T, AllBlocks> : never
export type FieldsOf<T extends BlockType> = BlockOf<T>['fields']
export type AnyBlockFields = FieldsOf<BlockType>
export type ValuesOf<T extends BlockType> = FieldsValue<FieldsOf<T>>
export type AnyBlockValues = ValuesOf<BlockType>

interface DataOf<T extends BlockType, V extends ValuesOf<T>> {
  id: PageBlockId,
  _type: T,
  name: string
  values: V
}


// Any block data
export type BlockDataOf<T extends BlockType> = T extends BlockType ? DataOf<T, ValuesOf<T>> : never

export type BlockData = BlockDataOf<BlockType>

export type BlockArray = ReadonlyArray<BlockData>

export function isDataOfType<T extends BlockType>(type: T, data: BlockData): data is BlockDataOf<T> {
  return data._type === type
}
export function isBlockOfType<T extends BlockType>(type: T, component: AllBlocks): component is BlockOf<T> {
  return component._type === type
}

const blockMap= fromPairs(AllBlocks.map(b => [b._type, b])) as {[key in BlockType]: BlockOf<key>}

// export function blockOfType<T extends BlockType>(type: T): BlockOf<T> {
export function blockOfType(type: BlockType): BlockOf<typeof type> {
  const cmp = blockMap[type]
  if (!cmp) {
    throw new Error(`Unknown block type ${type}`)
  }
  return cmp
}

export function renderBlock(block: BlockData): React.ReactNode {
  const cmp = blockOfType(block._type)
  // @ts-ignore no way to write generic code without manually going through each of BlockType
  return React.createElement(cmp, {
    key: block.id,
    id: block.id,
    values: block.values
  })
}

import React from 'react'
import { defineBlock, defineComponent } from './Block'
import { field as f } from './fields'
import { Container } from '@/components/tailwind'
import xw from 'xwind'
import {ButtonField} from '@blocks/fields/ButtonFieldDef'

const def = defineBlock('Content.1' as const, {
  blockName: 'Контент блок',
  fields: {
    title: f.text({
      label: 'Заголовок',
      defaultValue: 'Вы получите с нашими курсами'
    }),
    body: f.text({
      label: 'Текст',
      multiline: true,
      defaultValue: 'C нашими курсами вы получите самые необходимые знания и навыки, чтобы найти работу своей мечты.'
    }),
    image: f.image({
      label: `Изображение`,
      defaultValue: {
        src: 'https://cdn.glokurs.ru/school/558/54e80b5ae26d43f0aaade9e0464a90fd/:original-54e80b5ae26d43f0aaade9e0464a90fd.jpeg',
        width: 1920,
        height: 1280
      }
    }),
    imageAlignment: f.enum({
      label: 'Положение картинки',
      options: {
        Right: 'Справа',
        Left: 'Слева',
      }
    }),
    buttons: f.array({
      label: 'Кнопки и ссылки',
      items: f.button({
        label: '',
        defaultProps: {
          size: 'large'
        }
      }),
      itemTitle: item => item.title,
      defaultItems: [
        {
          title: 'Регистрация',
          type: 'primary',
          size: 'large',
          link: {
            _type: 'Page',
            page: 'Signup'
          }
        },
      ]
    })
  }
})

const Content1 = defineComponent(def, ({ id, values }) => {
  const { title, image, body, imageAlignment, buttons } = values
  const src = image?.src
  const alignment = imageAlignment

  return <Container blockId={id}>
    <section css={xw`text-gray-600`}>
      <div css={xw`container mx-auto flex py-24 md:flex-row flex-col items-center`}>
        {alignment === 'Left' &&
          <div css={xw`lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0 lg:mr-24 md:mr-16`}>
            <img css={xw`object-cover object-center rounded`} alt="hero" src={src}/>
          </div>
        }
        <div
          css={xw`lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left items-center text-center`}>
          <h1 css={xw` sm:text-4xl text-3xl mb-4 font-medium text-gray-900`}>{title}
          </h1>
          <p css={xw`mb-8 leading-relaxed`}>{body}</p>
          <div css={xw`flex space-x-3 justify-center md:justify-start mb-10 md:mb-0 `}>
            {buttons.map((b, i) => <ButtonField key={i} fieldProps={b}/>)}
          </div>
        </div>
        {alignment === 'Right' &&
        <div css={xw`lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0 lg:ml-24 md:ml-16`}>
          <img css={xw`object-cover object-center rounded`} alt="hero" src={src}/>
        </div>
        }
      </div>
    </section>
  </Container>
})

export default Content1

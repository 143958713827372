import type { Brand } from 'utility-types'
import type { CourseId, CourseTariffId, CourseTariffLink, PickByType } from './index'
import { difference } from 'ramda'

export type PageId = Brand<number, 'PageId'>

export const NonEnrollPageTypes = [
  'Landing',
  'Login',
  'Signup',
  'Home',
  'MyCourses',
  'PaymentWaiting'
] as const
export type NonEnrollPages = typeof NonEnrollPageTypes[number]

export const PageTypes = [
  ...NonEnrollPageTypes,
  'Enroll'
] as const
export type PageType = typeof PageTypes[number]

export const allPagesExpect = (...args: PageType[]): PageType[] => {
  return difference(PageTypes, [...args])
}

const cast = <T extends PageType[]>(...args: T): [...T] => [...args]

export const PageGroups = {
  Public: cast('Landing', 'Login', 'Signup', 'Enroll', 'PaymentWaiting'),
  Student: cast('Home', 'MyCourses')
}

interface PageBase {
  id: PageId
  _type: PageType
  title: string
  description: string
  browserTitle: string | null
}

// Лендинг школы
export interface LandingPage extends PageBase {
  _type: 'Landing'
}

export interface SignupPage extends PageBase {
  _type: 'Signup'
}

export interface LoginPage extends PageBase {
  _type: 'Login'
}

// Домашняя страница школы - Все курсы школы
export interface HomePage extends PageBase {
  _type: 'Home'
}

export interface MyCoursesPage extends PageBase {
  _type: 'MyCourses'
}

export interface EnrollPage extends PageBase {
  _type: 'Enroll'
  courseId: CourseId,
  tariffId: CourseTariffId
  link: CourseTariffLink
}

export interface PaymentWaitingPage extends PageBase {
  _type: 'PaymentWaiting'
}

export type SchoolPage =
  LandingPage |
  LoginPage |
  SignupPage |
  HomePage |
  MyCoursesPage |
  PaymentWaitingPage |
  EnrollPage

export type PageByType<T extends PageType> = PickByType<SchoolPage, T>

// Ниже эксперименты по рекурсивным conditional типам

// type _Rec<T> =
//   T extends [head: infer H, ...rest: infer Tail] ?
//     H extends PageType ? [PageByType<H>, ..._Rec<Tail>] : never
//     : T
// type Rec<T extends unknown[]> = _Rec<T>
// // type Tail<T extends any[]> = T extends [any, ...rest: ] ? H : never
//
// // type Rec<Source, Tail extends unknown[], Res> =
// //   Tail extends [] ? Res :
// //     H
// //     Rec<Source>
//
// // type Infer<T extends readonly PageType[]> = Rec<T>
// type Infer<T extends readonly PageType[]> = [...T]
//
// // export type ThemesPages = Rec<>
// export type ThemesPages = Rec<Infer<typeof PageTypes>>
//
// const s: ThemesPages = []


export type PageBlockId = Brand<string, 'PageBlockId'>

export interface LinkToPage {
  _type: 'Page'
  page: NonEnrollPages
}

export interface LinkToWeb {
  _type: 'Web'
  href: string
}

export interface LinkToEnroll {
  _type: 'Enroll'
  link: CourseTariffLink
  courseId: CourseId
  tariffId: CourseTariffId
}

export type LinkData = LinkToPage | LinkToWeb | LinkToEnroll

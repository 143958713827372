import { EnrollPage, LinkData, NonEnrollPages, SchoolPage } from '@/types'
import { NAV } from '@/nav'

export const pageUrl = (page: SchoolPage | NonEnrollPages): string => {
  let pageType = typeof page === 'string' ? page : page._type
  switch (pageType) {
    case 'Login':
      return NAV.Login
    case 'Signup':
      return NAV.Signup
    case 'PaymentWaiting':
      return NAV.PaymentWaiting
    case 'Enroll':
      return `/enroll/${(page as EnrollPage).link}`
    case 'Landing':
      return NAV.Landing
    case 'Home':
      return NAV.Home
    case 'MyCourses':
      return NAV.MyCourses
  }
}

export const pageUrlByLink = (link: LinkData): string => {
  switch (link._type) {
    case 'Enroll':
      return `/enroll/${link.link}`
    case 'Page':
      return pageUrl(link.page)
    case 'Web':
      return link.href
  }
}

import { FieldDef, FieldsDef, FieldsValue, FieldValue } from './base'
import { TextFieldDef } from './TextFieldDef'
import { CssColorFieldDef } from './CssColorFieldDef'
import { CssDimensionFieldDef } from './CssDimensionFieldDef'
import { ButtonFieldDef } from './ButtonFieldDef'
import { ArrayFieldDef } from './ArrayFieldDef'
import { NestedFieldDef } from './NestedFieldDef'
import { EnumFieldDef } from './EnumFieldDef'
import { BooleanFieldDef } from './BooleanFieldDef'
import { CourseTariffFieldDef } from './CourseTariffFieldDef'
import { ImageFieldDef, ImageFieldData } from './ImageFieldDef'
import { LinkFieldDef } from './LinkFieldDef'
import { MarkdownFieldDef } from './MarkdownFieldDef'

export const field = {
  text: TextFieldDef.create,
  boolean: BooleanFieldDef.create,
  css: {
    color: CssColorFieldDef.create,
    dimension: CssDimensionFieldDef.create
  },
  button: ButtonFieldDef.create,
  array: ArrayFieldDef.create,
  nested: NestedFieldDef.create,
  enum: EnumFieldDef.create,
  courseTariff: CourseTariffFieldDef.create,
  image: ImageFieldDef.create,
  link: LinkFieldDef.create,
  markdown: MarkdownFieldDef.create,
}

export type {
  FieldDef,
  FieldsDef,
  FieldValue,
  FieldsValue,
  ImageFieldData
}

export {
  TextFieldDef,
  CssColorFieldDef,
  CssDimensionFieldDef,
  ButtonFieldDef,
  ArrayFieldDef,
  NestedFieldDef,
  EnumFieldDef,
  BooleanFieldDef,
  CourseTariffFieldDef,
  ImageFieldDef,
  LinkFieldDef,
  MarkdownFieldDef,
}

import React from 'react'
import xw from 'xwind'

export const Container: React.FC<{
  fluid?: boolean
  blockId?: string
}> = ({fluid, blockId, children}) => {
  const props = blockId ? {'data-block': blockId} : {}
  return <div css={xw`container px-2 my-2`} {...props}>{children}</div>
}

import React, { useState } from 'react'
import { Link } from '@blocks'
import { defineBlock, defineComponent } from './Block'
import { useAuthContext, useCurrentSchool } from '@/auth/AuthContext'
import { Dropdown, Menu } from 'antd'
import { CloseOutlined, MenuOutlined} from '@ant-design/icons'
import { allPagesExpect, LinkData } from '@/types'
import { BlockDescription } from './base'
import xw from 'xwind'
import { pageUrl, pageUrlByLink } from '@/utils/pageUrl'
import { field as f } from './fields'
import styled from '@emotion/styled'
import { NAV } from '@/nav'
import NotificationsMenuItem from '@/components/notifications/NotificationMenuItem'
import { useRouter } from 'next/router'
import { colors } from '@/styles'
import { UserAvatar } from '@/components/avatar/UserAvatar'

const def = defineBlock('Header', {
  blockName: 'Меню навигации',
  isApplicableTo: allPagesExpect('Signup', 'Login'),
  fields: {

    unauthenticated: f.nested('Публичные', {
      menu: f.array({
        label: `Меню`,
        items: f.nested({
          title: f.text({
            label: `Текст`,
            defaultValue: 'Школа'
          }),
          link: f.link({
            label: 'Ссылка',
          })
        }),
        itemTitle: item => item.title,
        defaultItems: [
          { title: 'Регистрация', link: { _type: 'Page', page: 'Signup' } },
        ]
      }),
      loginBtn: f.text({
        label: 'Текст на кнопке входа',
        defaultValue: 'Вход'
      })
    }),

    authenticated: f.nested('Кабинет студента', {
      menu: f.array({
        label: `Меню`,
        items: f.nested({
          title: f.text({
            label: `Текст`,
            defaultValue: 'Все курсы'
          }),
          link: f.link({
            label: 'Ссылка',
            default: { _type: 'Page', page: 'Home' }
          })
        }),
        itemTitle: item => item.title,
        defaultItems: [
          { title: 'Школа', link: { _type: 'Page', page: 'Home' } },
          { title: 'Мои курсы', link: { _type: 'Page', page: 'MyCourses' } },
        ]
      })
    })
  },
  description: <BlockDescription>
    Это глобальный блок, т.е изменения будут сразу на всех страницы вашей школы.
  </BlockDescription>
})

const A = styled.a`
  padding: 0.5rem 0.75rem;
  white-space: nowrap;

  &:hover {
    color: ${colors.blue} !important;
  }
`

const MenuLink: React.FC<{ link: LinkData }> = ({ link, children }) => {
  const href = pageUrlByLink(link)
  const { pathname } = useRouter()
  const isActive = pathname.includes(href)
  return <Link href={href} passHref>
    <A style={{color: isActive ? colors.blue : colors.gray8}}>{children}</A>
  </Link>
}

const Header = defineComponent(def, ({
  id,
  values: {
    authenticated,
    unauthenticated
  }
}) => {
  const {
    avatar,
    name
  } = useCurrentSchool()
  const { user } = useAuthContext()

  const menu = (
    <Menu>
      <Menu.Item key="profile">
        <Link href={NAV.Profile}>Профиль</Link>
      </Menu.Item>
      <Menu.Item key="logout">
        <Link href="/logout">Выход</Link>
      </Menu.Item>
    </Menu>
  );

  const menuItems = (user ? authenticated.menu : unauthenticated.menu).filter(m => !!m.link)

  const [menuIsHidden, setMenuIsHidden] = useState(true)

  const onShowMenuClick = () => {
    setMenuIsHidden(!menuIsHidden)
  }

  const menuIcon = () => {
    if (menuIsHidden) {
      return <MenuOutlined/>
    }
    return <CloseOutlined/>
  }

  return <nav css={xw`container px-2`} data-block={id}>
    <div css={xw`flex flex-auto items-center justify-between h-16 max-w-full`}>

      <div css={xw`flex-initial md:hidden`}>
        <button
          css={xw`cursor-pointer p-2 text-gray-700 hover:text-gray-900 rounded focus:outline-none`}
          onClick={onShowMenuClick}>
          <span css={xw`sr-only`}>Открыть меню</span>
          {menuItems.length > 0 && menuIcon()}
        </button>
      </div>

      <div css={xw`flex flex-grow items-center justify-center md:justify-start min-w-0`}>
        {avatar && <div css={xw`flex-initial`}>
          <div css={xw`w-10 block`}>
            <img src={avatar} alt={name}/>
          </div>
        </div>}
        <div css={xw`min-w-0 flex-initial hidden md:block truncate pl-4 cursor-default`}>
            <span css={xw`text-black text-opacity-90 subpixel-antialiased`}>
              {name}
            </span>
        </div>
      </div>

      <div css={xw`flex flex-initial items-center pl-4 space-x-2`}>
        {menuItems.length > 0 && (
          <div css={xw`hidden md:block`}>
            {menuItems.map((item, i) =>
              <MenuLink key={i} link={item.link}>{item.title}</MenuLink>
            )}
          </div>
        )}

        {user && <NotificationsMenuItem/>}

        {!user && (
          <div css={xw`flex-initial`}>
            <Link href={pageUrl('Login')} passHref>
              <button
                css={xw`py-2 px-3 text-white text-sm font-medium transition bg-blue-500 hover:bg-blue-600 rounded shadow cursor-pointer`}>
                {unauthenticated.loginBtn}
              </button>
            </Link>
          </div>
        )}

        {user && (
          <div css={xw`flex-initial`}>
            <Dropdown overlay={menu}>
              <div css={xw`rounded-full overflow-hidden cursor-pointer`}>
                <UserAvatar user={user} />
              </div>
            </Dropdown>
          </div>
        )}
      </div>
    </div>

    {menuItems.length > 0 &&
      <div css={xw`md:hidden`} id="mobile-menu">
        {!menuIsHidden &&
          <div css={xw` pt-2 pb-3 space-y-1`}>
            {menuItems.map((item, i) =>
              <div key={i}
                css={xw`text-black text-opacity-90 block py-2 bg-opacity-75 border-l-4 border-transparent hover:border-l-4 hover:border-blue-400`}>
                <MenuLink link={item.link}>{item.title}</MenuLink>
              </div>,
            )}
          </div>
        }
      </div>
    }
  </nav>
})

export default Header

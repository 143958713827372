import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Button, Modal, Typography } from 'antd'
import { colors, LayoutSize, respondTo } from '@/styles'
import Link from 'next/link'

export interface CourseDetailsProps {
  title: string
  description: string|null
  label?: string|null
  path?: string|null
  url?: string
}

interface CourseDetailsModalProps {
  visible: boolean
  details: CourseDetailsProps
  onCancel: () => void
}

const CourseDescriptionContent = styled.div`
  color: ${colors.white} !important;

  ${respondTo(LayoutSize.MOBILE, `
    display: none;
  `)}
`

const { Title, Text } = Typography

const CourseDetailsModal: React.FC<CourseDetailsModalProps> = ({ visible, details, onCancel }) => (
  <Modal
    title={null}
    visible={visible}
    onCancel={onCancel}
    footer={null}
    destroyOnClose={true}
  >
    <Title level={3}>{details.title}</Title>
    <Text style={{ display: 'block', margin: '1rem 0 2rem', whiteSpace: 'pre-wrap' }}>
      {details.description}
    </Text>
    {details.path && <Link href={details.path} passHref prefetch={false}>
      <a className="ant-btn ant-btn-primary">
        {details.label}
      </a>
    </Link>}
    {details.url && <a href={details.url} className="ant-btn ant-btn-primary">{details.label}</a>}
  </Modal>
)

export const CourseDescription: React.FC<CourseDetailsProps> = ({ title, description, path, label, url }) => {
  const [visible, setVisible] = useState(false)
  if (!description) {
    return null
  }
  return (
    <CourseDescriptionContent>
      <Typography.Paragraph ellipsis={{
        rows: 2,
        expandable: false,
      }}>
        {description}
      </Typography.Paragraph>
      <div style={{textAlign: 'right', marginTop: '0.5rem'}}>
        <Button size="small" shape="round" onClick={() => setVisible(true)}>Подробнее</Button>
      </div>
      <CourseDetailsModal
        details={{ title, description, path, label, url }}
        visible={visible}
        onCancel={() => setVisible(false)}
      />
    </CourseDescriptionContent>
  )
}

import { ImageGradient } from '@/graphql/generated'

export enum LayoutSize {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  LAPTOP = 'laptop',
}


export const boxShadows = {
  regular: '0px 1px 2px rgba(100, 100, 100, 0.1)',
  regularHovered: '0px 1px 8px rgba(100, 100, 100, 0.1)'
}

export const colors = {
  black: 'rgba(0, 0, 0, 0.85)',
  white: '#FFF',
  green: '#95DE64',
  green3: '#B7EB8F',
  green4: '#95DE64',
  green6: '#52C41A',
  orange: '#FAAD14',
  orange3: '#FFD591',
  orange4: '#FFC069',
  orange6: '#FA8C16',
  red3: '#FFA39E',
  red5: '#F5222D',
  red6: '#F5222D',
  volcano: '#FF9C6E',
  volcano3: '#FFBB96',
  volcano6: '#FA541C',
  blue: '#1890FF',
  blue1: '#E6F7FF',
  blue2: '#BAE7FF',
  blue3: '#91D5FF',
  blue4: '#69C0FF',
  blue6: '#1890FF',
  geekBlue: '#F0F5FF',
  deepBlue: '#4C77A6',
  gray9: '#262626',
  gray8: '#595959',
  gray7: '#8C8C8C',
  gray6: '#BFBFBF',
  gray5: '#D9D9D9',
  gray4: '#E8E8E8',
  gray3: '#F5F5F5',
  gray1: '#FAFAFA',
  gray: '#F5F5F5',
  gold: '#FAAD14',
  gold1: '#FFFBE6',
  gold3: '#FFE58F',
  [ImageGradient.Orange]: '#F29718',
  [ImageGradient.Fuchsia]: '#F953C6',
  [ImageGradient.Green]: '#45B649',
  [ImageGradient.Purple]: '#8E2DE2',
  [ImageGradient.Black]: '#000',
  [ImageGradient.Clean]: '#8C8C8C',
}

export const gradients = {
  [ImageGradient.Orange]: 'linear-gradient(116.57deg, rgba(242,151,24,0.9) 0%, rgba(242,151,24,0.2) 100%)',
  [ImageGradient.Fuchsia]: 'linear-gradient(116.57deg, rgba(249,83,198,0.9) 0%, rgba(249,83,198,0.2) 100%)',
  [ImageGradient.Green]: 'linear-gradient(116.57deg, rgba(69,182,73,0.9) 0%, rgba(69,182,73,0.2) 100%)',
  [ImageGradient.Purple]: 'linear-gradient(116.57deg, rgba(142,45,226,0.9) 0%, rgba(142,45,226,0.2) 100%)',
  [ImageGradient.Black]: 'linear-gradient(116.57deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.2) 100%)',
  [ImageGradient.Clean]: '',
}

export const layout = {
  [LayoutSize.MOBILE]: 768,
  [LayoutSize.TABLET]: 992,
  [LayoutSize.LAPTOP]: 1080
}

export const respondTo = (size = LayoutSize.LAPTOP, content: string) =>
  `@media only screen and (max-width: ${layout[size]||0}px) {${content}}`

export const withGradient = (gradient: ImageGradient, image?: string|null) => {
  if (gradient === ImageGradient.Clean) {
    return image ? `url(${image}) no-repeat` : colors[gradient]
  }
  if (image) {
    return `${gradients[gradient]}, url(${image}) no-repeat`
  }
  return gradients[gradient]
}

export const gradientStyles = (gradient: ImageGradient, image?: string|null) => ({
  background: withGradient(gradient, image),
  backgroundSize: 'cover'
})

export function plural(
  count: number,
  one: string,
  two: string,
  five: string
): string {
  count = Math.floor(Math.abs(count)) % 100
  if (count > 10 && count < 20) {
    return five
  }
  count = count % 10
  if (1 === count) {
    return one
  }
  if (count >= 2 && count <= 4) {
    return two
  }
  return five
}

export function lecturePlural(count: number) {
  return count + ' ' + plural(count, 'урок', 'урока', 'уроков')
}

import React from 'react'
import { defineBlock, defineComponent } from './Block'
import { field as f } from './fields'
import { Container } from '@/components/tailwind'
import xw from 'xwind'

const def = defineBlock('Image' as const, {
  blockName: 'Изображение',
  fields: {
    image: f.image({
      label: `Изображение`,
    }),
    caption: f.text({
      label: 'Подпись'
    }),
    width: f.enum({
      label: 'Ширина блока',
      options: {
        Full: 'По ширине страницы',
        Medium: 'Средняя',
        Small: 'Узкая'
      }
    })
  }
})

const ImageBlock = defineComponent(def, ({ id, values }) => {
  const { caption, image, width } = values

  if (!image) {
    return null
  }

  return <Container blockId={id}>
    <figure css={[xw`py-6 mx-auto`,
      width === 'Full' && xw`w-full`,
      width === 'Medium' && xw`w-10/12`,
      width === 'Small' && xw`w-8/12 md:w-6/12`
    ]}>
      <img src={image.src} alt={caption} css={xw`my-0 mx-auto`}/>
      {caption && (
        <figcaption css={xw`text-center text-base my-4 text-gray-500 text-opacity-80`}>{caption}</figcaption>
      )}
    </figure>
  </Container>
})

export default ImageBlock

import { Flatten } from '@/types'

export abstract class FieldDef<Type> {
  readonly _type!: Type

  abstract default(): Type
}

export type FieldsDef = Record<string, FieldDef<any>>

export type FieldValue<F extends FieldDef<any>> = F['_type']
export type FieldsValue<Fields extends FieldsDef> = Flatten<{ [field in keyof Fields]: FieldValue<Fields[field]> }>

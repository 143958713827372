export const min2hours = (minutes: number) => {
  const hours = Math.floor(minutes / 60)
  const min = minutes % 60
  if (!min) {
    return `${hours} ч`
  }
  if (!hours) {
    return `${min} мин`
  }
  return `${hours} ч ${min} мин`
}
